import React, { FC } from 'react'
import { getProductFromWorkflow, Workflow } from '../domain/workflow'
import { useAuth } from '../../auth/hooks/useAuth'
import { Error as DError, isValidationError } from '../../../shared/domain/error'
import { useGetWorkflowByUUID } from '../hooks/useGetWorkflowByUUID'
import { useEditWorkflow } from '../hooks/useEditWorkflow'
import { WorkflowRepository } from '../domain/workflow.repository'
import { WorkflowForm } from '../../../components/forms/WorkflowForm'
import { useNavigate, useParams } from 'react-router-dom'
import { Loader } from '../../../components/Loader'
import { Error } from '../../../components/Error'
import { toastError, toastSuccess } from '../../../components/Toaster'
import { useGetSettings } from '../hooks/useGetSettings'
import { Header } from '../../../components/ui/Header'
import { Container } from '../../../components/ui/Container'
import { Card } from '../../../components/ui/Card'

export const WorkflowEdit: FC<{ repository: WorkflowRepository }> = ({ repository }) => {
  const params = useParams<{ uuid: string }>()
  const user = useAuth()
  const settingsQuery = useGetSettings(repository)
  const workflow = useGetWorkflowByUUID(repository, params.uuid ?? '')
  const editWorkflow = useEditWorkflow(repository, params.uuid ?? '')
  const navigate = useNavigate()

  if (workflow.status === 'loading' || settingsQuery.status === 'loading') {
    return <Loader message={'Loading data...'}/>
  }

  if (workflow.status === 'error' || settingsQuery.status === 'error') {
    return (
            <Container className={'flex justify-center mt-20'}>
                <Error message={'Error loading your data'}/>
            </Container>
    )
  }

  if (settingsQuery.status !== 'success') {
    return (
            <Container className={'flex justify-center mt-20'}>
                <Error message={'Error retrieving settings'}/>
            </Container>
    )
  }

  if (workflow.status !== 'success') {
    return (
            <Container className={'flex justify-center mt-20'}>
                <Error message={'Error retrieving data'}/>
            </Container>
    )
  }

  const handleSubmit = (workflow: Workflow) => {
    editWorkflow.mutateAsync(workflow).then((data) => {
      const product = getProductFromWorkflow(data.workflow)
      toastSuccess('Workflow updated')
      navigate({ pathname: '/workflows', search: `?product=${product.replace(/\s+/g, '-')}` })
    }).catch((error: DError) => {
      toastError(error)
    })
  }

  const errors = ((editWorkflow.error != null) && isValidationError(editWorkflow.error)) ? editWorkflow.error.errors : {}

  return (
        <Container>
            <section className={'flex flex-col gap-4 mb-2 items-center'}>
                <Header title={`Edit Workflow: ${workflow.data.workflow.name}`}/>
                <Card className={'w-full'}>
                    <h1 className={'text-center font-semibold text-xl my-4'}>Edit Workflow</h1>
                    <div className={'w-full flex items-center flex-col bg-white p-2'}>
                        <div className={'w-5/6 md:w-3/5'}>
                            <WorkflowForm companyName={user.user?.companyName!} errors={errors}
                                          handleSubmit={handleSubmit}
                                          isSubmitting={editWorkflow.isLoading}
                                          workflow={workflow.data.workflow}
                                          settings={settingsQuery.data}
                            />
                        </div>
                    </div>
                </Card>
            </section>
        </Container>
  )
}
