import { Navigate, Outlet } from 'react-router-dom'
import { ErrorBoundary } from './ErrorBoundary'
import { useAuth } from '../auth/hooks/useAuth'

export const UnAuthLayout = () => {
  const { user } = useAuth()
  if (user != null) {
    return <Navigate to={'/calculator'}/>
  }
  return (<main className={'flex place-content-center items-center h-screen'}>
        <ErrorBoundary>
            <Outlet/>
        </ErrorBoundary>
    </main>)
}
