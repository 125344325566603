import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { Error } from '../shared/domain/error'
import { ReactNode } from 'react'
import { toastError } from './Toaster'

const client = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      const _error = error as Error
      return toastError(_error)
    }
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const QueryProvider = ({ children }: { children: ReactNode }) => {
  return (
        <QueryClientProvider client={client}>
          {
            children
          }
        </QueryClientProvider>
  )
}
