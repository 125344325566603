import { ValidationErrors } from '../../../shared/domain/error'
import { FC } from 'react'
import { InputField } from '../../formik/InputField'
import { Select } from '../../formik/Select'
import { ActionApi, InputList, SettingsApi } from '../../../sections/workflows/domain/workflow'
import { FieldArrayRenderProps } from 'formik'
import { camelCaseSeparator } from '../../../shared/utils/string'
import { AddonInputs } from './actions/AddonInputs'
import { PriceInputs } from './actions/PriceInputs'
import { Error } from '../../Error'
import Button from '../../ui/Button'

type ActionRecord = Record<string, ActionApi>

interface ActionInputProps {
  name: string
  type: string
  index: number
  errors: ValidationErrors
  helpers: FieldArrayRenderProps
  settingsData: SettingsApi
}

const getActionTypes = (settings: any) => {
  const actions: ActionRecord = {}
  settings.actions.forEach((action: ActionApi) => {
    actions[action.type] = action
  })
  return actions
}

const actionTypesOptions = (settings: any) => {
  const actionTypes = getActionTypes(settings)
  const options: Array<{ label: string, value: string }> = []
  Object.keys(actionTypes).forEach((key: string) => options.push({ label: camelCaseSeparator(key), value: key }))
  return options
}

const getActionInputs = (actions: ActionRecord, type: string, name: string, index: number, errors: ValidationErrors) => {
  if (actions[type] !== undefined) {
    switch (actions[type]?.input_type) {
      case 'price':
        return <PriceInputs name={name} index={index} errors={errors}/>
      case 'addon':
        return <AddonInputs name={name} index={index} errors={errors}/>
    }
    if (actions[type]?.inputs !== null || actions[type]?.inputs !== undefined) {
      const inputs = actions[type].inputs as InputList
      const elements: JSX.Element[] = []
      Object.keys(inputs).forEach(id => {
        switch (inputs[id].type) {
          case 'number':
            elements.push(<div key={id} className={'w-full md:w-1/4 px-3'}><InputField name={`${name}[${index}].${id}`} label={inputs[id].label} type={'number'} error={errors}/></div>)
            break
          case 'text':
            elements.push(<div key={id} className={'w-full md:w-1/4 px-3'}><InputField name={`${name}[${index}].${id}`} label={inputs[id].label} type={'text'} error={errors}/></div>)
            break
        }
      })
      if (elements.length >= 1) {
        return elements
      }
    }
  }
  if (type === 'Choose a value' || type === '') {
    return
  }
  return <Error message={'No defined inputs for this action type'}/>
}

export const ActionInputs: FC<ActionInputProps> = ({ name, type, index, errors, helpers, settingsData }) => {
  return (<article key={index}
                     className={'p-2 bg-gray-300 rounded  border border-gray-200 shadow-md'}>
        <div className={'flex justify-between items-center'}>
            <h3 className='font-semibold'>Action #{index + 1}</h3>
            <Button classes='my-4' outline={true} buttonColor='error' onClick={() => helpers.remove(index)}>x</Button>
        </div>
        <div className="flex flex-wrap -mx-3">
            <div className={'w-full md:w-1/4 px-3'}>
                <Select options={actionTypesOptions(settingsData)} name={`${name}[${index}].type`}
                        label={'Type'} type={'select'} error={errors}/>
            </div>
            {getActionInputs(getActionTypes(settingsData), type, name, index, errors)}
        </div>
    </article>)
}
