import { useState } from 'react'
import { Error } from '../../../shared/domain/error'
import { CalculatorRequest } from '../domain/calculator.request'
import { CalculatorRepository } from '../domain/calculator.repository'
import { CalculatorResponse } from '../domain/calculator.response'

export const useCalculator = (repo: CalculatorRepository) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [data, setData] = useState<CalculatorResponse | null>(null)

  const mutateAsync = async (data: CalculatorRequest) => {
    try {
      setError(null)
      setIsLoading(true)
      setData(null)
      const response = await repo.submit(data)
      setIsLoading(false)
      console.log({ response })
      setData(response)
    } catch (e) {
      setIsLoading(false)
      setError(e as Error)
      throw e
    }
  }

  return { data, isLoading, error, mutateAsync }
}
