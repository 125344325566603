import React from 'react'
import { Router } from './Router'
import { QueryProvider } from './components/QueryProvider'
import { Toaster } from './components/Toaster'

function App () {
  return (
        <QueryProvider>
            <Router/>
            <Toaster/>
        </QueryProvider>
  )
}

export default App
