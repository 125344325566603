import { Response, WorkflowRepository } from '../domain/workflow.repository'
import { getProductsAndWorkflows, Workflow } from '../domain/workflow'
import { useMutation, useQueryClient } from 'react-query'
import { Error } from '../../../shared/domain/error'

export const useAddWorkflow = (repository: WorkflowRepository) => {
  const addWf = async (wf: Workflow) => await repository.addWorkflow(wf)
  const queryClient = useQueryClient()
  const {
    error,
    isLoading,
    data,
    isSuccess,
    mutateAsync
  } = useMutation<{ workflow: Workflow }, Error, Workflow>('add-workflow', addWf, {
    onSuccess: (data) => {
      queryClient.setQueryData('workflows-get-all', (oldQueryData: Response['getAll'] | undefined) => {
        const newWorkflows = [data.workflow, ...oldQueryData?.workflows ?? []]
        const { products, workflows: workflowsOrderedByProduct } = getProductsAndWorkflows(newWorkflows)
        return { workflows: newWorkflows, products, workflowsOrderedByProduct }
      })
    }
  })

  return {
    error,
    mutateAsync,
    isLoading,
    data,
    isSuccess
  }
}
