import { useLogin } from '../hooks/useLogin'
import { isValidationError } from '../../../shared/domain/error'
import { AuthRepository } from '../domain/auth.repository'
import { FC } from 'react'
import { LoginForm } from '../../../components/forms/LoginForm'
import { toastError, toastSuccess } from '../../../components/Toaster'

interface Props {
  repository: AuthRepository
  passwordResetUrl: string
}

export const Login: FC<Props> = (props) => {
  const { signIn, error, data } = useLogin(props.repository)

  if (error != null && !isValidationError(error)) {
    toastError(error)
  }

  if (data != null) {
    toastSuccess('Login succeeded')
  }
  const errors = error?._tag === 'ValidationError' ? error?.errors : {}

  return (
        <div className='w-full max-w-md'>
            <LoginForm
                errors={errors}
                handleSubmit={signIn}
                passwordResetUrl={props.passwordResetUrl}
            />
        </div>
  )
}
