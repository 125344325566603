import { Response, WorkflowRepository } from '../domain/workflow.repository'
import { getProductsAndWorkflows, Workflow } from '../domain/workflow'
import { useMutation, useQueryClient } from 'react-query'
import { Error } from '../../../shared/domain/error'

export const useEditWorkflow = (repository: WorkflowRepository, workflowUUID: string) => {
  const editWf = async (wf: Workflow) => await repository.editWorkflow(workflowUUID, wf)
  const queryClient = useQueryClient()
  const {
    error,
    isLoading,
    mutateAsync,
    data,
    isSuccess
  } = useMutation<{ workflow: Workflow }, Error, Workflow>('edit-workflow', editWf, {
    onSuccess: (data) => {
      queryClient.setQueryData('workflows-get-all', (oldQueryData: Response['getAll'] | undefined) => {
        const _oldWorkflows = (oldQueryData?.workflows ?? []).filter(wf => wf.uuid !== data.workflow.uuid)
        const newWorkflows = [data.workflow, ..._oldWorkflows]
        const { products, workflows: workflowsOrderedByProduct } = getProductsAndWorkflows(newWorkflows)
        return { workflows: newWorkflows, products, workflowsOrderedByProduct }
      })
    }
  })

  return {
    error,
    isLoading,
    mutateAsync,
    data,
    isSuccess
  }
}
