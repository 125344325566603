import { getUserName, User } from '../sections/auth/domain/user'
import Button from './ui/Button'
import React, { FC } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems } from './Menu'
import { LogoutIcon } from './icons/LogoutIcon'

interface Props {
  user: User
  logout: () => void
  displayRight?: boolean
}

export const Logout: FC<Props> = ({ user, logout, displayRight = true }) => {
  const userName = getUserName(user)
  return (
        <Menu>
            <MenuButton as={Button}
                        buttonColor='error'
                        classes='bg-white text-black hover:opacity-90 hover:opacity-75 px-3 py-2'>{userName}</MenuButton>
            <MenuItems className={displayRight ? 'right-0' : 'left-0'}>
                <div className="px-1 py-1 ">
                    <MenuItem>
                        <Button
                            classes={'bg-white text-black hover:bg-black hover:text-white w-full text-left flex justify-start'}
                            onClick={logout}>
                            <span className={'mr-2'}>
                                <LogoutIcon/>
                            </span>
                            logout
                        </Button>
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
  )
}
