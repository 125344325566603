import { FC } from 'react'
import { Spinner } from './icons/Spinner'

interface Props {
  message: string
}
export const Loader: FC<Props> = ({ message }) => {
  return (
        <div className={'h-full flex place-content-center'}>
            <div role="status" className={'flex flex-col items-center my-2'}>
                <Spinner className={'fill-red-600'}/>
                <p>{message}</p>
            </div>

        </div>
  )
}
