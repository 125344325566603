import { FunctionComponent, ReactNode, useState } from 'react'
import { ArrowIcon } from './icons/ArrowIcon'

interface Props {
  children: ReactNode
  title?: string
}
export const Accordion: FunctionComponent<Props> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
        <div
            className={'w-full bg-white shadow-md rounded-md sm:overflow-hidden cursor-pointer'}>
            <div className='flex py-2 px-2 justify-between items-center shadow-md rounded-md'
                 onClick={() => setIsOpen(prev => !prev)}>

                <h1 className={'font-bold'}>{title}</h1>

                <ArrowIcon isOpen={isOpen}/>

            </div>

            {isOpen && <div className={'my-2 p-2 overflow-hidden transition-transform transition-all duration-1000'}>{
                children
            }</div>}

        </div>
  )
}
