import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useField, useFormikContext } from 'formik'
import React, { FC } from 'react'
import { Errors } from '../ui/fields/Errors'

interface Props {
  name: string
  label: string
  error: Record<string, string>
}

export const DatePicker: FC<Props> = (props) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  const _error = props.error[props.name] != null ? props.error[props.name] : ''
  return (
        <>
            <label className="block  text-sm font-semibold my-1" htmlFor={props.name}>
                {props.label}
            </label>

            <ReactDatePicker
                {...field}
                className={`input-ghost-primary rounded max-w-full bg-white input-xs input input-ghost-primary border focus:outline-none focus:shadow-outline bg-white ${(_error !== '') ? 'border-red-900' : 'border-gray-900'}`}
                dateFormat="yyyy/MM/dd hh:mm"
                selected={field.value ?? new Date()}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                autoComplete={'off'}
                timeCaption="time"
                onChange={(date: Date) => setFieldValue(field.name, date)}
                todayButton={'Today'}/>

            <Errors message={_error}/>
        </>
  )
}
