type Section = Record<string, {
  name: string
  role: RoleTypes
}>

interface Role {
  isOverallUser: boolean
  isOverallAdmin: boolean
  canManageUsers: boolean
  app: string
  sections: Section
}

export interface User {
  email: string
  companyName: string
  isSuperAdmin: boolean
  auth: {
    token: string
    expire: Date
  }
  role: Role
}

export type SectionTypes = 'workflows' | 'calculator'
export type RoleTypes = 'admin' | 'user'
const getIsSuperAdmin = (user: User) => user.isSuperAdmin
const getIsOverallAdmin = (user: User) => user.role.isOverallAdmin
const getSections = (user: User) => Object.keys(user.role.sections)
const getSection = (user: User, section: SectionTypes) => user.role.sections[section]

export const getCanAccess = (section: SectionTypes, role: RoleTypes) => (user: User) => {
  const isSuperAdmin = getIsSuperAdmin(user)
  if (isSuperAdmin) {
    return true
  }
  const isOverAllAdmin = getIsOverallAdmin(user)
  if (isOverAllAdmin) {
    return true
  }
  const sections = getSections(user)
  if (!sections.includes(section)) {
    return false
  }

  const { role: sectionRole } = getSection(user, section)

  if (role === 'admin' && sectionRole === 'user') {
    return false
  }
  return sectionRole === role
}

export const getCanAccessWorkflows = (user: User) => getCanAccess('workflows', 'admin')(user)
export const getUserName = (user: User) => {
  const email = user.email
  const [name] = email.split('@')
  if (name.includes('.')) {
    const [firstName, lastName] = name.split('.')
    return `${firstName} ${lastName}`
  }
  return name
}
export const getCompanyName = (user: User) => user.companyName
