import { FC } from 'react'

interface Props {
  title: string
}
export const Header: FC<Props> = ({ title }) => {
  return (
    <header className='w-full bg-gray-900 text-center py-2'>
      {<h2 className='font-semibold text-xl text-white capitalize'>{title}</h2>}
    </header>
  )
}
