import React, { Fragment, type FC, type ReactNode } from 'react'
import { Menu as HeadlessMenu, Transition } from '@headlessui/react'

export const MenuButton = HeadlessMenu.Button

export interface MenuProps {
  children: ReactNode
}

export const Menu: FC<MenuProps> = ({ children }) => {
  return (
        <HeadlessMenu as={'div'} className={'relative'}>
            {children}
        </HeadlessMenu>
  )
}

export interface MenuItemProps {
  children: ReactNode
}

export const MenuItem: FC<MenuItemProps> = ({ children }) => {
  return (
        <HeadlessMenu.Item>
            {() => (
                <div>
                    {children}
                </div>
            )}
        </HeadlessMenu.Item>
  )
}

export interface MenuItemsProps {
  children: ReactNode
  className: string
}

export const MenuItems: FC<MenuItemsProps> = ({ children, className }) => {
  return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"

        >
            <HeadlessMenu.Items
                className={'absolute mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ' + className}>
                {children}
            </HeadlessMenu.Items>

        </Transition>
  )
}
