
import { FC } from 'react'
import { ValidationErrors } from '../../../../shared/domain/error'
import { InputField } from '../../../formik/InputField'
import { RadioField } from '../../../formik/RadioField'

interface AddonInputProps {
  name: string
  index: number
  errors: ValidationErrors
}

export const AddonInputs: FC<AddonInputProps> = ({ name, index, errors }) => {
  return (
      <>
      <div className={'w-full md:w-1/4 px-3'}>
          <InputField classes='rounded' name={`${name}[${index}].addon.label`} label={'Label'}
                 type={'text'}
                 error={errors}/>
      </div>
      <div className={'w-full md:w-1/4 px-3'}>
          <InputField classes='rounded' name={`${name}[${index}].addon.type`} label={'type'}
                 type={'text'}
                 error={errors}/>
      </div>

      <div className={'w-full md:w-1/4 px-3'}>
          <InputField classes='rounded' name={`${name}[${index}].addon.discount`} label={'Amount'}
                 type={'number'}
                 error={errors}/>
      </div>
      <div className={'w-full md:w-1/4 px-3'}>
          <InputField classes='rounded' name={`${name}[${index}].addon.price`}
                 label={'Percentage'}
                 type={'number'}
                 error={errors}/>
      </div>
      <div className={'w-full md:w-1/4 px-3'}>
          <RadioField name={`${name}[${index}].addon.required`} label={'Required'} error={errors}/>
      </div>
    </>
  )
}
