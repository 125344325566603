import { useState } from 'react'
import { useAuth } from './useAuth'
import { Error } from '../../../shared/domain/error'
import { User } from '../domain/user'
import { AuthRepository } from '../domain/auth.repository'

export const useLogin = (repo: AuthRepository) => {
  const { login, logout } = useAuth()
  const [data, setData] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const signIn = async (email: string, password: string) => {
    try {
      setError(null)
      setIsLoading(true)
      setError(null)
      setData(null)
      const response = await repo.signIn(email, password)
      setIsLoading(false)
      login(response)
      setData(response)
    } catch (e) {
      setIsLoading(false)
      setError(e as Error)
    }
  }

  const signOut = () => logout()
  return { isLoading, error, signIn, data, signOut }
}
