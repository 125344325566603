import { ReactNode, FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  buttonColor?: 'primary' | 'secondary' | 'success' | 'error'
  buttonSize?: 'xs' | 'sm' | 'md' | 'lg'
  children: ReactNode
  onClick?: () => void
  classes?: string
  href?: string
  type?: 'button' | 'submit'
  disabled?: boolean
  fullWidth?: boolean
  outline?: boolean
}

const Button: FC<Props> = ({
  buttonColor = 'primary',
  buttonSize = 'sm',
  fullWidth = false,
  children,
  outline = false,
  onClick,
  classes = '',
  type = 'button',
  disabled = false,
  href
}) => {
  return href != null
    ? (
    <Link
      to={href}
      className={`btn btn-${buttonColor} btn-${buttonSize} ${
        fullWidth ? 'btn-block' : ''
      } ${disabled ? 'disabled' : ''} ${classes} }`}
      onClick={onClick}
    >
      {children}
    </Link>
      )
    : (
    <button
      type={type}
      disabled={disabled}
      className={`rounded btn ${outline ? `btn-outline-${buttonColor}` : `btn-${buttonColor}`} btn-${buttonSize} ${
        fullWidth ? 'btn-block' : ''
      } ${disabled ? 'disabled' : ''} ${classes}`}
      onClick={onClick}
    >
      {children}
    </button>
      )
}

export default Button
