import React, { FC } from 'react'
import { List } from '../../sections/calculator/domain/lists'
import { Field } from 'formik'
import { Select as SelectField } from '../ui/fields/Select'

type FieldProps = Partial<HTMLInputElement> & {
  label: string
  classes?: string
  type: string
  name: string
  error: Record<string, string>
}

export const Select: FC<FieldProps & { options: List[] }> = (props) => {
  return (
        <Field {...props} as={SelectField}/>
  )
}
