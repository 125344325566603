import { FC, useState } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from '@hello-pangea/dnd'
import Button from './ui/Button'

const getListClassNames = (isDraggingOver: boolean) =>
  `p-2 border rounded mt-2 ${isDraggingOver ? 'bg-red-100' : 'bg-light-gray'} `
const getItemClassNames = (isDragging: boolean) =>
  `p-2 border rounded mt-2 ${
    isDragging ? 'bg-green-100 border-black' : 'bg-white border-2'
  } select-none p-4 m-4 rounded`

// eslint-disable-next-line @typescript-eslint/member-delimiter-style
type Item<T> = T & { dragId: string; name?: string }

interface Props<T> {
  items: Array<Item<T>>
  cancelText: string
  saveText: string
  disableOnSave?: boolean
  handleSave: (items: Array<Item<T>>) => void
  handleCancel: () => void
}
interface TitleProps {
  name: string
  index: number
}
const Title: FC<TitleProps> = (props) => {
  return <span>{props.name !== '' ? props.name : props.index}</span>
}

export const Drag = <T extends unknown>({
  items,
  handleSave,
  handleCancel,
  saveText,
  cancelText,
  disableOnSave = false
}: Props<T>) => {
  const [list, setList] = useState([...items])
  const onSave = () => handleSave(list)

  const onCancel = () => handleCancel()

  const reorder = (
    list: Array<Item<T>>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (result.destination == null) {
      return
    }
    const items = reorder(list, result.source.index, result.destination.index)
    setList(() => [...items])
  }
  return (
    <div className={'w-full'}>
      <div className={'flex justify-between gap-2 w-full mt-8'}>
        <Button outline={true} buttonColor='error' onClick={onCancel}>
          {cancelText}
        </Button>
        <Button outline={true} buttonColor='primary' onClick={onSave} disabled={disableOnSave}>
          {saveText}
        </Button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'droppable'}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={getListClassNames(snapshot.isDraggingOver)}
            >
              {list.map((item, index) => (
                <Draggable
                  key={item.dragId}
                  draggableId={item.dragId}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={getItemClassNames(snapshot.isDragging)}
                    >
                      <Title name={item?.name ?? ''} index={index + 1} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
