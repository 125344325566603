import React, { ReactNode } from 'react'

interface DependantFieldProps<Fields> {
  field: Fields[keyof Fields]
  value: Fields[keyof Fields]
  children: ReactNode
}

export const DependantField = <Fields extends {}>({
  field,
  value,
  children
}: DependantFieldProps<Fields>) => field === value ? <>{children}</> : null
