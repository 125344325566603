import { useAuth } from '../../sections/auth/hooks/useAuth'
import { useEffect } from 'react'
import { clearInterceptors, setupInterceptors } from '../infrastructure/axios'

export const useInterceptors = () => {
  const { logout } = useAuth()
  useEffect(() => {
    const interceptor = setupInterceptors(logout)
    return () => clearInterceptors(interceptor)
  }, [])
}
