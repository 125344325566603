import { FC } from 'react'
import { Formik } from 'formik'
import { InputField } from '../formik/InputField'
import Button from '../ui/Button'

import { ValidationErrors } from '../../shared/domain/error'

interface Props {
  errors: ValidationErrors
  handleSubmit: (email: string, password: string) => Promise<void>
  passwordResetUrl: string
}

export const LoginForm: FC<Props> = ({ handleSubmit, passwordResetUrl, errors }) => {
  return (
        <div className='sm:mx-auto sm:w-full sm:max-w-lg'>
            <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md mb-4'>
                    <h2 className='my-2 text-left text-2xl font-bold tracking-tight  '>
                        Login
                    </h2>
                    <h3 className='my-2 text-left text-lg font-semibold tracking-tight '>
                        IRevolution Marketplace | Pricing Tool
                    </h3>
                </div>
                <Formik
                    initialValues={{ email: '', password: '' }}
                    onSubmit={async (values) => await handleSubmit(values.email, values.password)}
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <form
                            onSubmit={handleSubmit}
                            className=' bg-white py-8 px-8 shadow sm:rounded-lg sm:px-10 space-y-6'
                        >
                            <div className='mb-4'>
                                <InputField
                                    error={errors}
                                    className={''}
                                    type={'email'}
                                    name={'email'}
                                    label={'Email'}
                                    placeholder={'email'}
                                    classes='input-ghost-primary max-w-full rounded'
                                />
                            </div>
                            <div className='mb-4'>
                                <InputField
                                    error={errors}
                                    className={''}
                                    type={'password'}
                                    name={'password'}
                                    label={'Password'}
                                    placeholder={'password'}
                                    classes='input-ghost-primary max-w-full rounded'
                                />
                            </div>

                            <div className='flex-col items-center justify-between'>
                                <Button
                                    buttonColor={'primary'}
                                    type={'submit'}
                                    disabled={isSubmitting}
                                    fullWidth={true}
                                >
                                    Sign In
                                </Button>
                                <div className='relative'>
                                    <div className='mt-6 inset-0 flex flex-col'>
                                        <div className='w-full border-t border-gray-300'/>
                                        <a
                                            className='mt-4 font-medium text-sm text-indigo-600 hover:text-indigo-500'
                                            href={passwordResetUrl}
                                        >
                                            Forgot Password?
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
  )
}
