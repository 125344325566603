import { FC } from 'react'

type AlertColor = 'danger' | 'warning' | 'success' | 'info'

interface Props {
  color: AlertColor
  message: string
}

const colors: Record<AlertColor, string> = {
  danger: 'bg-red-200 text-red-900 dark:bg-red-200 dark:text-red-800',
  info: 'text-blue-700 bg-blue-100 dark:bg-blue-200 dark:text-blue-800',
  success: 'text-green-700 bg-green-100 dark:text-green-800 dark:bg-green-200 ',
  warning: 'text-yellow-700 bg-yellow-100 dark:bg-yellow-200 dark:text-yellow-800'
}

export const Alert: FC<Props> = ({ color, message }) => {
  return (

            <div className={`p-4 mb-4 text-sm  rounded-lg ${colors[color]}`}
                 role="alert">
                <span className="font-medium">{message}</span>
            </div>

  )
}
