import React from 'react'
import { classNames } from '../../shared/utils/classnames'
import { getCols } from '../../shared/utils/gridLayout'

interface Sizes {
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

interface Props extends Sizes {
  cols: number
  children: React.ReactNode
  classes: string
}

export const Grid: React.FunctionComponent<Props> = ({ cols, children, classes, md, sm, lg, xl }) => {
  return (
    <div className={classNames('grid', getCols(cols, [['sm', sm], ['md', md], ['lg', lg], ['xl', xl]]), classes)}>
      {children}
    </div>
  )
}

export const Col: React.FC<Props> = ({ classes, cols, children }) => {
  return (
      <div className={classNames('col-span-' + String(cols), classes)}>
        {children}
      </div>
  )
}
