/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { FC, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'
import { BarsIcon } from './icons/BarsIcon'
import { CloseIcon } from './icons/CloseIcon'

interface NavItem {
  title: string
  path: string
}

interface Props {
  navItems: NavItem[]
  home: ReactNode
  navRight?: ReactNode
  mobileNavRight: ReactNode
}

const MobileNavItem: FC<{ item: NavItem }> = ({ item }) => {
  return (<NavLink to={item.path}
    >{({ isActive }) => (
        <div
            className={`px-3 py-2 rounded text-base font-semibold hover:bg-red-700 ${isActive ? 'bg-white text-black' : 'text-white'}`}>
            {item.title}
        </div>
    )
    }</NavLink>)
}

const Navbar: FC<Props> = ({ navItems, navRight, home, mobileNavRight }) => {
  return (
        <Disclosure as='nav' className='bg-error'>
            {({ open }) => (
                <>
                    <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
                        <div className='relative flex h-16 items-center justify-between'>
                            <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                                {/* Mobile menu button */}
                                <Disclosure.Button
                                    className='inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                                    <span className='sr-only'>Open main menu</span>
                                    {open
                                      ? (
                                            <CloseIcon className='block h-6 w-6' aria-hidden='true'/>
                                        )
                                      : (
                                            <BarsIcon className='block h-6 w-6' aria-hidden='true'/>
                                        )}
                                </Disclosure.Button>
                            </div>
                            <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                                <div className='flex flex-shrink-0 items-center'>
                                  {home}
                                </div>

                                <div className='hidden sm:ml-6 sm:block sm:flex sm:items-center sm:space-x-4'>
                                    {navItems.map((item) => (
                                        <NavLink
                                            key={item.title}
                                            to={item.path}
                                        >
                                            {({ isActive }) => (
                                                <span
                                                    className={`px-3 py-2 text-sm font-semibold hover:opacity-75 rounded ${isActive ? 'bg-white text-black' : 'text-white '}`}>
                                                        {item.title}
                                                    </span>
                                            )}
                                        </NavLink>
                                    ))}
                                </div>

                            </div>
                            <div
                                className='hidden sm:flex flex-1 items-center justify-center sm:items-stretch sm:justify-end'>
                                {navRight}
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className='sm:hidden'>
                        <div className='space-y-1 px-2 pt-2 pb-3 flex flex-col '>
                            {navItems.map((item) => (
                                <Disclosure.Button
                                    key={item.title}
                                    as={MobileNavItem}
                                    item={item}
                                />
                            ))}
                            {mobileNavRight}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
  )
}

export default Navbar
