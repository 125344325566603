export interface UnExpectedError {
  _tag: 'UnExpectedError'
  message: string
}
export type ValidationErrors = Record<string, string>
export interface ValidationError {
  _tag: 'ValidationError'
  errors: ValidationErrors
}

export type Error = UnExpectedError | ValidationError

export const toUnExpectedError = (error: any): UnExpectedError => ({
  _tag: 'UnExpectedError',
  message: String(error)
})

export const toValidationError = (errors: ValidationErrors): ValidationError => ({
  _tag: 'ValidationError',
  errors
})

export const isValidationError = (error: Error): error is ValidationError => {
  return error._tag === 'ValidationError'
}
