import { FC, ReactElement } from 'react'

interface ListItem {
  name: string
  icon: ReactElement
}
interface Props {
  items: ListItem[]
  handleSelected: (item: string) => void
  itemSelected: string
  title?: string
}

export const Aside: FC<Props> = ({
  items,
  handleSelected,
  itemSelected,
  title
}) => {
  const listItems = items.map((item, i) => (
    <li
      key={i}
      className={`hover:bg-gray-300 ${
        itemSelected === item.name ? 'bg-gray-300' : 'bg-gray-100'
      } rounded`}
    >
      <button
        onClick={() => handleSelected(item.name)}
        className='flex items-center p-2 text-base font-normal  rounded-lg'
      >
        {item.icon}
        <span className='ml-3 capitalize  font-semibold'>{item.name}</span>
      </button>
    </li>
  ))

  return (
    <aside className='w-64' aria-label='Sidebar'>
      <div className='overflow-y-auto py-4 px-3 bg-white shadow-lg rounded '>
        {title !== undefined && title !== '' && (
          <h3 className={'text-left p-2  font-semibold'}>{title}</h3>
        )}
        <ul className='space-y-2'>{listItems}</ul>
      </div>
    </aside>
  )
}
