export interface Addon {
  required: boolean
  label: string
  type: string
  price: number
  discount: number
  applied?: boolean
}
export interface Fee {
  label: string
  type: string
  amount: number
}
export interface Quote {
  uuid: string
  paymentType: 'annually' | 'monthly'
  premium: number
  total: number
  monthly?: {
    apr: number
    interestRate: number
    months: number
    instalments: number
    initial: number
    requestLowInitial: boolean
  }
  expirationDate: Date
  premiumDiscount: number
  addons: Addon[]
  fees: Fee[]
  discount: number
}

export interface CalculatorResponse {
  requestUUID: string // the one that we sent
  quotes: Quote[]
}

export const filterAppliedAddons = (addons: Addon[]) => addons.filter(addon => addon.applied)
