import React, { FC } from 'react'

interface ErrorsProps {
  message: string
}

export const Errors: FC<ErrorsProps> = ({ message }) => {
  if (message === '') {
    return <div className='mt-1 h-4'></div>
  }
  return <div className='text-red-800 text-xs font-medium mt-1'>{message}</div>
}
