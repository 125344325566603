import { FC } from 'react'
import { FieldArray, getIn, useFormikContext } from 'formik'
import {
  Filter, SettingsApi,
  Workflow
} from '../../../sections/workflows/domain/workflow'
import Button from '../../ui/Button'

import { ValidationErrors } from '../../../shared/domain/error'
import { FilterInputs } from './FilterInputs'

interface FiltersProps {
  name: string
  errors: ValidationErrors
  title: string
  settingsData: SettingsApi
}

export const Filters: FC<FiltersProps> = ({ name, errors, title, settingsData }) => {
  const { values } = useFormikContext<Workflow>()
  const filters = getIn(values, name, []) as Filter[]
  return (
        <FieldArray name={`${name}`} render={helpers => {
          return <section className={'flex flex-col gap-2'}>
                {
                    filters.map((filter, index) => {
                      return <FilterInputs key={index} name={name} title={title} filter={filter} index={index} errors={errors} helpers={helpers} settingsData={settingsData}/>
                    })
                }
                <div>
                    <Button
                        buttonColor={'success'}
                        classes='btn-outline-success my-1'
                        outline={true}
                        onClick={() => helpers.push(settingsData.defaultFormValues.filter)}
                    >
                        Add {title}
                    </Button>
                </div>
            </section>
        }}/>
  )
}
