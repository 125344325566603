import { Request, Response, WorkflowRepository } from '../domain/workflow.repository'
import { useMutation, useQueryClient } from 'react-query'
import { Error } from '../../../shared/domain/error'
import { getProductsAndWorkflows, Workflow } from '../domain/workflow'

export const useSaveWorkflowReorder = (repository: WorkflowRepository) => {
  const postReorder = async (data: Request['saveReorder']) => await repository.saveWorkflowReorder(data)
  const queryClient = useQueryClient()

  const query = useMutation<{ workflows: Workflow[] }, Error, Request['saveReorder']>('save-reorder', postReorder, {
    onSuccess: (data) => {
      queryClient.setQueryData('workflows-get-all', (oldQueryData: Response['getAll'] | undefined) => {
        const newWorkflowsUUIDS = data.workflows.map(w => w.uuid)
        const previousWorkflows = oldQueryData?.workflows?.filter(w => !newWorkflowsUUIDS.includes(w.uuid)) ?? []
        const newWorkflows = [...previousWorkflows, ...data.workflows]
        const { products, workflows: workflowsOrderedByProduct } = getProductsAndWorkflows(newWorkflows)
        return { workflows: newWorkflows, products, workflowsOrderedByProduct }
      })
    }
  })
  return {
    error: query.error,
    isLoading: query.isLoading,
    mutateAsync: query.mutateAsync,
    data: query.data,
    isSuccess: query.isSuccess
  }
}
