import React, { useState, FC } from 'react'
import { useGetAllWorkflowsByCompanyName } from '../hooks/useGetAllWorkflowsByCompanyName'
import { useSaveWorkflowReorder } from '../hooks/useSaveWorkflowReorder'
import { Error } from '../../../components/Error'
import { Loader } from '../../../components/Loader'
import {
  DraggableWorkflow,
  getIsSameWorkflowsOrder,
  reorderWorkflows,
  Workflow
} from '../domain/workflow'
import { useAuth } from '../../auth/hooks/useAuth'
import Button from '../../../components/ui/Button'
import { WorkflowRepository } from '../domain/workflow.repository'
import { Drag } from '../../../components/Drag'
import { WorkflowList } from '../../../components/WorkflowList'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Aside } from '../../../components/ui/Aside'
import { ProductIcon } from '../../../components/icons/ProductIcon'
import { Card } from '../../../components/ui/Card'
import { toastError, toastSuccess } from '../../../components/Toaster'
import { toUnExpectedError } from '../../../shared/domain/error'
import { serializeFormQuery } from '../../../shared/utils/search.params'
import { Header } from '../../../components/ui/Header'
import { Container } from '../../../components/ui/Container'

interface Props {
  repository: WorkflowRepository
}

interface DragOrListProps {
  isDraggable: boolean
  isLoading: boolean
  product: string
  handleDraggable: () => void
  draggableList: DraggableWorkflow[]
  handleSaveReorder: (Workflows: DraggableWorkflow[]) => void
  workflows: Workflow[]
  handleEditWorkflow: (workflow: Workflow) => void
}

interface MenuProps {
  product: string
  handleAdd: () => void
  draggable: boolean
  handleDraggable: () => void
  displaySortButton: boolean
}

const NavMenu: FC<MenuProps> = ({
  product,
  draggable,
  handleDraggable,
  handleAdd,
  displaySortButton
}) => {
  if (product === '') {
    return (
            <div className={'flex justify-end'}>
                <Button
                    buttonColor='success'
                    outline={true}
                    classes='font-semibold'
                    onClick={handleAdd}
                >
                    +
                </Button>
            </div>
    )
  }

  return (
        <div className={'flex flex-wrap justify-end items-center gap-2 p-2'}>
            {!draggable && displaySortButton && (
                <Button
                    buttonColor={'primary'}
                    outline={true}
                    classes='font-semibold'
                    onClick={handleDraggable}
                >
                    Sort Workflows
                </Button>
            )}
            <Button
                buttonColor='success'
                onClick={handleAdd}
                outline={true}
            >
                +
            </Button>
        </div>
  )
}

const DragOrList: FC<DragOrListProps> = (props) => {
  if (props.isDraggable) {
    return (
            <Drag
                saveText={'Save'}
                cancelText={'Cancel'}
                disableOnSave={props.isLoading}
                items={props.draggableList}
                handleSave={props.handleSaveReorder}
                handleCancel={props.handleDraggable}
            />
    )
  }
  return (
        <WorkflowList
            product={props.product}
            workflows={props.workflows}
            handleEditWorkflow={props.handleEditWorkflow}
        />
  )
}

export const Workflows: FC<Props> = (props) => {
  const { user } = useAuth()
  const workflowsQuery = useGetAllWorkflowsByCompanyName(
    props.repository,
    user?.companyName!
  )
  const reorderQuery = useSaveWorkflowReorder(props.repository)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const [draggable, setDraggable] = useState(false)
  const productFromUrl = searchParams.get('product') ?? ''

  const handleNewWorkflow = () => navigate('/workflow/add')
  const handleEditWorkflow = (wf: Workflow) => navigate(`/workflow/edit/${wf.uuid}`)

  const handleClickProduct = (product: string) => {
    const params = serializeFormQuery({ product })
    handleCloseDraggable()
    setSearchParams(params)
  }

  const handleSaveReorder = (draggableWorkflows: DraggableWorkflow[]) => {
    const isSameOrder = getIsSameWorkflowsOrder(workflows, draggableWorkflows)
    if (isSameOrder) {
      return toastError(toUnExpectedError('Order is the same'))
    }
    const orderedWorkflow = reorderWorkflows(workflows, draggableWorkflows)
    reorderQuery
      .mutateAsync({
        companyName: user?.companyName!,
        orders: orderedWorkflow.map((w) => ({ uuid: w.uuid, order: w.order }))
      })
      .then(() => {
        handleDraggable()
        toastSuccess('Reorder saved successfully')
      })
      .catch(() => toastError(toUnExpectedError('Error saving reorder')))
  }

  const handleDraggable = () => setDraggable((prev) => !prev)
  const handleCloseDraggable = () => setDraggable(false)

  if (workflowsQuery.status === 'loading') {
    return <Loader message={'workflows...'}/>
  }

  if (workflowsQuery.status === 'error' || workflowsQuery.status !== 'success') {
    return <Container className={'flex justify-center mt-20'}>
            <Error message={'Error retrieving data'}/>
        </Container>
  }

  const { data: { products, workflowsOrderedByProduct } } = workflowsQuery
  const workflows = workflowsOrderedByProduct[productFromUrl] ?? []

  return (
        <Container>
            <section className={'flex flex-col gap-4 mb-2 items-center'}>
                <Header title={'workflows'}/>
                <div className='flex flex-wrap justify-center md:justify-between gap-2 w-full'>
                    <Aside
                        items={products.map((product) => ({
                          icon: <ProductIcon/>,
                          name: product
                        }))}
                        itemSelected={productFromUrl}
                        handleSelected={handleClickProduct}
                        title={'Workflows'}
                    />
                    <div className={'w-full sm:w-3/4 h-full'}>
                        <Card>
                            <NavMenu
                                handleDraggable={handleDraggable}
                                draggable={draggable}
                                handleAdd={handleNewWorkflow}
                                product={productFromUrl}
                                displaySortButton={workflows.length > 0}
                            />

                            {productFromUrl !== ''
                              ? (
                                    <div className={'w-full p-2'}>
                                        <DragOrList
                                            product={productFromUrl}
                                            draggableList={workflows.map((i) => ({ ...i, dragId: i.uuid }))}
                                            handleEditWorkflow={handleEditWorkflow}
                                            workflows={workflows}
                                            handleDraggable={handleDraggable}
                                            handleSaveReorder={handleSaveReorder}
                                            isDraggable={draggable}
                                            isLoading={reorderQuery.isLoading}
                                        />
                                    </div>
                                )
                              : (
                                    <h3 className={'text-center font-semibold'}>
                                        Select a workflow or create a new one
                                    </h3>
                                )}
                        </Card>
                    </div>
                </div>
            </section>
        </Container>
  )
}
