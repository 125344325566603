import {
  EmptyWorkflow, SettingsApi,
  Step, Workflow
} from '../../sections/workflows/domain/workflow'
import { idGenerator } from '../../shared/utils/string'
import { FC, useState } from 'react'
import { ValidationErrors } from '../../shared/domain/error'
import { FieldArray, Form, Formik, useFormikContext } from 'formik'
import { InputField } from '../formik/InputField'
import { RadioField } from '../formik/RadioField'

import Button from '../ui/Button'
import { Modify } from '../../shared/utils/type.utilities'
import { Drag } from '../Drag'
import { Filters } from './workflow/Filters'
import { Actions } from './workflow/Actions'

interface FormProps {
  workflow?: Workflow
  companyName: string
  errors: ValidationErrors
  handleSubmit: (workflow: Workflow) => void
  isSubmitting: boolean
  settings: SettingsApi
}

const getDefaultStep = (companyName: string, step?: Step): Modify<Step, { active: string | boolean, continue: string | boolean }> => ({
  actions: [],
  filters: [],
  active: 'false',
  continue: 'true',
  uuid: '',
  companyName,
  ...step
})

const getInitialValues = (companyName: string, wf?: Workflow): Workflow | EmptyWorkflow => (wf != null)
  ? wf
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  : {
      companyName,
      scope: [],
      steps: [],
      name: '',
      order: ''
    } as EmptyWorkflow

export const WorkflowForm: FC<FormProps> = ({
  workflow,
  companyName,
  errors,
  isSubmitting,
  handleSubmit,
  settings

}) => {
  const [draggable, setDraggable] = useState(false)
  const handleDraggable = () => setDraggable(prev => !prev)

  return (
        <Formik
            initialValues={getInitialValues(companyName, workflow)}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values as Workflow)}>
            {({
              values
            }) => {
              return (
                    <Form className={'flex flex-col gap-3 max-w-[1200px] mx-auto'}>
                        <section className='mb-4'>
                            <InputField error={errors} classes='input-sm input-ghost-primary rounded' type={'text'}
                                        name={'name'} label={'Workflow Name'} placeholder={'workflow name'}/>

                            <InputField error={errors} classes='input-ghost-primary rounded' type={'text'}
                                        name={'companyName'} label={'Company Name'} placeholder={'company name'}
                                        disabled
                                        hidden={true}
                                        />
                               <InputField error={errors} classes='input-ghost-primary rounded' type={'number'}
                                           name={'order'} label={'Order'} placeholder={'order'}
                                           hidden={true}
                                           disabled/>

                        </section>
                        <section>
                            <Filters name={'scope'} errors={errors} title={'Overall Filter'} settingsData={settings}/>
                        </section>
                        <hr/>
                        <section>
                            <Button
                                buttonColor='primary'
                                classes='my-6'
                                type={'button'}
                                onClick={handleDraggable}
                                outline={true}
                            >
                                Sort Steps
                            </Button>
                            {draggable
                              ? <DragStep handleDraggable={handleDraggable}/>
                              : <FieldArray name={'steps'} render={helpers => {
                                return <section className={'flex flex-col gap-2'}>
                                        {
                                            values.steps.map((step, i) => (
                                                <article key={i}
                                                         className={`hover:bg-gray-200 p-2 ${i % 2 === 0 ? 'bg-gray-50 border-gray-200' : 'bg-gray-100 border-gray-300'} rounded  border shadow-md`}>

                                                    <div className={'flex justify-between items-center'}>
                                                        <h3 className='font-semibold text-xl'>Step #{i + 1}</h3>

                                                    </div>
                                                    <div className={'my-2'}/>
                                                    <div className="flex flex-wrap -mx-3">
                                                        <div className={'w-full md:w-1/4 px-3 mb-4'}>
                                                            <RadioField name={`steps[${i}].continue`}
                                                                        label={'Continue'} error={errors}/>
                                                        </div>
                                                        <div className={'w-full md:w-1/4 px-3'}>
                                                            <RadioField name={`steps[${i}].active`}
                                                                        label={'Active'} error={errors}/>
                                                        </div>
                                                    </div>
                                                    <div className={'my-2'}/>
                                                    <Filters name={`steps[${i}].filters`} errors={errors}
                                                             title={'Filter'} settingsData={settings}/>
                                                    <div className={'my-2'}/>
                                                    <hr/>
                                                    <div className={'my-2'}/>

                                                    <Actions name={`steps[${i}].actions`} errors={errors}
                                                             settingsData={settings}/>
                                                    <Button fullWidth={true} classes='my-6 btn-outline-error'
                                                            buttonColor='error' outline={true}
                                                            onClick={() => helpers.remove(i)}>
                                                        {`Remove Step ${i + 1}`}
                                                    </Button>
                                                </article>))

                                        }
                                        <div>
                                            <Button
                                                classes='mt-8'
                                                fullWidth={true}
                                                outline={true}
                                                buttonColor={'success'}
                                                type={'button'}
                                                onClick={() =>
                                                  helpers.push(getDefaultStep(companyName))
                                                }
                                            >
                                                Add Step
                                            </Button>
                                        </div>
                                    </section>
                              }}/>}
                        </section>
                        <section className={'text-right mt-8'}>
                            <Button
                                buttonColor='primary'
                                classes='btn-primary'
                                disabled={isSubmitting}
                                type={'submit'}
                                fullWidth={true}
                            >
                                Save
                            </Button>
                        </section>
                    </Form>
              )
            }}
        </Formik>
  )
}

const DragStep: FC<{ handleDraggable: () => void }> = ({ handleDraggable }) => {
  const { values, setFieldValue } = useFormikContext<Workflow>()

  const steps = values.steps.map((s, index) => ({
    ...s,
    name: `step ${index + 1}`,
    dragId: s.uuid !== '' ? s.uuid : idGenerator()
  }))

  const handleSave = (steps: Array<Step & { dragId: string, name: string }>) => {
    setFieldValue('steps', steps.map(({ dragId, name, ...rest }) => ({ ...rest })))
    handleDraggable()
  }
  return (
        <Drag
            cancelText={'Cancel'}
            saveText={'Save'}
            items={steps}
            handleSave={handleSave}
            handleCancel={handleDraggable}
        />
  )
}
