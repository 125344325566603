import React from 'react'
import { Calculator } from './Calculator'
import { calculatorApiRepository } from '../infrastructure/calculator.api.repository'
import { http } from '../../../shared/infrastructure/http'
import { useAuth } from '../../auth/hooks/useAuth'
const calculatorRepository = calculatorApiRepository(http)

export const CalculatorFactory = () => {
  const { user } = useAuth()

  return <Calculator repository={calculatorRepository(user?.auth.token!)}/>
}
