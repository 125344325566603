import type { PersistentStorage } from '../domain/persistence.storage'

export const customLocalStorage: PersistentStorage = {
  getItem<T>(key: string) {
    const item = window.localStorage.getItem(key)
    if (item === null) return undefined
    if (item === 'null') return null
    if (item === 'undefined') return undefined

    try {
      return JSON.parse(item) as T
    } catch {
      return null
    }
  },
  setItem (key: string, value: any) {
    if (value === undefined) {
      window.localStorage.removeItem(key)
    } else {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  }
}
