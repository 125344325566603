import { NavLink, Outlet } from 'react-router-dom'
import { useAuth } from '../auth/hooks/useAuth'
import { ErrorBoundary } from './ErrorBoundary'
import Navbar from '../../components/Navbar'
import { getCanAccess, getCompanyName, RoleTypes, SectionTypes, User } from '../auth/domain/user'
import { Logout } from '../../components/Logout'

interface NavbarLink {
  section: SectionTypes
  role: RoleTypes
  title: string
  path: string
}

const getNavbarLinks = (user: User): NavbarLink[] => {
  const links: NavbarLink[] = [
    {
      section: 'workflows',
      role: 'admin',
      title: 'Workflows',
      path: '/workflows'
    },
    {
      section: 'calculator',
      role: 'user',
      title: 'Calculator',
      path: '/calculator'
    }
  ]
  return links.filter(link => getCanAccess(link.section, link.role)(user))
}

export const Layout = () => {
  const { logout, user } = useAuth()
  const links = getNavbarLinks(user!)
  const companyName = getCompanyName(user!)
  return (
        <div className={'h-screen w-full flex flex-col'}>
            <Navbar
                home={ <NavLink
                    to='/'
                    className='text-white text-2xl font-bold cursor-pointer'
                >
                    Pricing Tool <span className={'text-xs uppercase'}>{`(${companyName})`}</span>
                </NavLink>}
                navItems={links}
                navRight={<Logout logout={logout} user={user!}/>}
                mobileNavRight={<Logout user={user!} logout={logout} displayRight={false}/>}
            />
            <main className={'h-full w-full '}>
                <ErrorBoundary>
                    <Outlet/>
                </ErrorBoundary>
            </main>
        </div>
  )
}
