import { ReactNode } from 'react'

interface TableProps <TItem> {
  headers: string[]
  items: TItem[]
  renderCol: (item: TItem, itemIndex: number) => ReactNode
  renderHeader: (header: string[]) => ReactNode

}
export function Table<TItem> (props: TableProps<TItem>) {
  return (
    <table className="min-w-full ">
        <thead>
        {
            props.renderHeader(props.headers)
        }
        </thead>
        <tbody>
        {
            props.items.map(props.renderCol)
        }
        </tbody>
    </table>
  )
}
