import { Component, ErrorInfo, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../components/ui/Container'
import { Card } from '../../components/ui/Card'

export class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  state = {
    hasError: false
  }

  public static getDerivedStateFromError (_: Error) {
    return { hasError: true }
  }

  public componentDidCatch (_error: Error, _errorInfo: ErrorInfo) {
    // console.error("Uncaught error:", error, errorInfo);
  }

  private resetError () {
    this.setState({ hasError: false })
  }

  render () {
    if (this.state.hasError) {
      return (
                <Container className={'h-full w-full flex place-content-center items-center'}>
                    <Card className={'bg-red-200 text-red-900 flex flex-col items-center  p-4 gap-4'}>
                        <h2>Something went wrong.</h2>
                        <Link onClick={this.resetError} to={'/'}
                              className={'rounded bg-white text-black font-semibold hover:opacity-75 px-3 py-2'}>
                            Return to home
                        </Link>
                    </Card>
                </Container>
      )
    }

    return this.props.children
  }
}
