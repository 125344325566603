import { toUnExpectedError, toValidationError } from '../domain/error'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { config } from '../../config'

export const axiosInstance = axios.create({ baseURL: config.backendUrl })

const onError = (logout: () => void) => async (error: AxiosError) => {
  if (!axios.isAxiosError(error)) {
    return await Promise.reject(toUnExpectedError(error))
  }

  if (error.response?.data.errors != null && Object.keys(error.response?.data.errors).length > 0) {
    return await Promise.reject(toValidationError(error.response.data.errors))
  }

  if (error.response?.status === 401) {
    logout()
  }

  const _error = error?.response?.data?.message ?? error?.message
  return await Promise.reject(toUnExpectedError(_error))
}
const onSuccess = (response: AxiosResponse) => response

export const setupInterceptors = (logout: () => void) => {
  return axiosInstance.interceptors.response.use(
    onSuccess,
    onError(logout)
  )
}
export const clearInterceptors = (id: number) => axiosInstance.interceptors.response.eject(id)

export const post = async <TRequest, TResponse>(url: string, data: TRequest, options?: Record<any, any>) => await axiosInstance.post<TRequest, AxiosResponse>(url, data, options).then(res => res.data as TResponse)
export const patch = async <TRequest, TResponse>(url: string, data: TRequest, options?: Record<any, any>) => await axiosInstance.patch<TRequest, AxiosResponse>(url, data, options).then(res => res.data as TResponse)
export const get = async <TRequest, TResponse>(url: string, data?: TRequest, options?: Record<any, any>) => await axiosInstance.get<TResponse, AxiosResponse>(url, {
  ...options,
  data
}).then(res => res.data as TResponse)
