import { FC } from 'react'
import { Alert } from './ui/Alert'

interface Props {
  message: string
}
export const Error: FC<Props> = ({ message }) => {
  return (
        <Alert color={'danger'} message={`Error: ${message}`}/>
  )
}
