import React, { FC, InputHTMLAttributes } from 'react'
import { Errors } from './Errors'

interface Option {
  value: string
  label: string
}

type Props = InputHTMLAttributes<HTMLSelectElement> & {
  error: Record<string, string>
  name: string
  label: string
  options: Option[]
}

export const Select: FC<Props> = ({ name, error, label, options, ...rest }) => {
  const _error = error[name] != null ? error[name] : ''
  return <div className={'form-control'}>
        <label className="text-sm font-semibold m-1" htmlFor={name}>
            {label}
        </label>
        <select
            {...{ ...rest, name }}
            className={`select select-xs select-ghost-primary text-sm appearance-none border rounded w-full px-3 leading-tight focus:outline-none focus:shadow-outline ${(_error !== '') ? 'border-red-900' : 'border-gray-900'}`}
        >
            <option value={''} className={'camel'}>Choose a value</option>
            {
                options.map(({ value, label }) => <option key={value} value={value}>{label.toLowerCase()}</option>)
            }

        </select>
        <Errors message={_error}/>
    </div>
}
