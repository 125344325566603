import React, { FC } from 'react'
import { getProductFromWorkflow, Workflow } from '../domain/workflow'
import { useAuth } from '../../auth/hooks/useAuth'
import { isValidationError } from '../../../shared/domain/error'
import { useAddWorkflow } from '../hooks/useAddWorkflow'
import { WorkflowRepository } from '../domain/workflow.repository'
import { WorkflowForm } from '../../../components/forms/WorkflowForm'
import { toastError, toastSuccess } from '../../../components/Toaster'
import { Error } from '../../../components/Error'
import { useGetSettings } from '../hooks/useGetSettings'
import { useNavigate } from 'react-router-dom'
import type { Error as DError } from '../../../shared/domain/error'
import { Loader } from '../../../components/Loader'
import { Header } from '../../../components/ui/Header'
import { Container } from '../../../components/ui/Container'
import { Card } from '../../../components/ui/Card'

export const WorkflowAdd: FC<{ repository: WorkflowRepository }> = ({ repository }) => {
  const data = useAuth()
  const settingsQuery = useGetSettings(repository)
  const { mutateAsync: addWorkflow, error, isLoading: isSubmitting } = useAddWorkflow(repository)
  const navigate = useNavigate()

  const handleSubmit = (workflow: Workflow) => {
    addWorkflow(workflow).then((data) => {
      toastSuccess('Workflow created')
      const product = getProductFromWorkflow(data.workflow)
      navigate({ pathname: '/workflows', search: `?product=${product.replace(/\s+/g, '-')}` })
    }).catch((error: DError) => {
      toastError(error)
    })
  }

  if (settingsQuery.status === 'loading') {
    return <Loader message={'getting settings...'}/>
  }

  if (settingsQuery.status !== 'success') {
    return (
            <Container className={'flex justify-center mt-20'}>
                <Error message={'Error retrieving setting'}/>
            </Container>
    )
  }

  const errors = ((error != null) && isValidationError(error)) ? error.errors : {}

  return (
        <Container>
            <section className={'flex flex-col gap-4 mb-2 items-center'}>
                <Header title={'Add Workflow'}/>
                <Card className={'w-full'}>
                    <h3 className={'text-center font-semibold my-4 text-xl'}>New Workflow</h3>
                    <div className={'w-full flex items-center flex-col bg-white p-2'}>
                        <div className={'w-3/5'}>
                            <WorkflowForm companyName={data.user?.companyName!} errors={errors}
                                          handleSubmit={handleSubmit}
                                          isSubmitting={isSubmitting} settings={settingsQuery.data}/>
                        </div>
                    </div>
                </Card>
            </section>
        </Container>
  )
}
