import type { User } from '../domain/user'
import type { Http } from '../../../shared/domain/http'
import { AuthRepository } from '../domain/auth.repository'

export const authApiRepository = (http: Http): AuthRepository => {
  return {
    signIn: async (email, password) => await http.post<{ email: string, password: string }, User>('/auth/login', { email, password }, {
      headers: {
        'Content-type': 'application/json'
      }
    })
  }
}
