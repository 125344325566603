import { FC } from 'react'
import {
  Action, ActionType,
  Filter, getIsAnActionPrice,
  getIsEqualityFilter,
  orderWorkflowsAsc,
  Step,
  Workflow
} from '../sections/workflows/domain/workflow'
import Button from './ui/Button'

import { Accordion } from './Accordion'
import { formatPrice } from '../shared/utils/number'

interface Props {
  product: string
  workflows: Workflow[]
  handleEditWorkflow: (workflow: Workflow) => void
}

const Filters: FC<{ filters: Filter[] }> = ({ filters }) => {
  if (filters.length <= 0) {
    return (
            <>
                No filters applied
            </>
    )
  }
  return (
        <>
            {filters.map((filter, i) => (
                <article key={i} className={'capitalize'}>
                    {filter.type} {filter.apply}{' '}
                    {getIsEqualityFilter(filter)
                      ? filter.equal
                      : `${filter?.min ?? 'X'} - ${filter.max ?? 'X'}`}
                </article>
            ))}
        </>
  )
}
const PriceAction: FC<{ action: Action }> = ({ action }) => {
  if (action.price == null) {
    return <></>
  }
  return (
        <>
            {action.price?.label} - amount: {formatPrice(action?.price?.amount)}, percentage: {action.price?.percentage}%
        </>
  )
}
const AddonAction: FC<{ action: Action }> = ({ action }) => {
  if (action.addon == null) {
    return <></>
  }
  return (
        <>
            {action.addon?.label} - price: {formatPrice(action.addon?.price)}, discount: {formatPrice(action.addon?.discount)}
        </>
  )
}
const Actions: FC<{ actions: Action[] }> = ({ actions }) => {
  if (actions.length <= 0) {
    return (
        <>
            No actions applied
        </>
    )
  }
  return (
        <>
            {actions.map((action, i) => (
                <article key={i} className={'capitalize'}>
                    {
                        getIsAnActionPrice(action.type as ActionType)
                          ? <PriceAction action={action}/>
                          : <AddonAction action={action}/>
                    }
                </article>
            ))}
        </>
  )
}
const Scope: FC<{ scope: Filter[] }> = ({ scope }) => {
  return (
        <section>
            <h4 className={'font-bold'}>Overall Filters</h4>
            <Filters filters={scope}/>
        </section>
  )
}

const Steps: FC<{ steps: Step[] }> = ({ steps }) => {
  return (
        <section>
            <h4 className={'font-bold'}>Steps</h4>
            {steps.map((step, i) => (
                <article className='my-1' key={i}>
                    <div className={`rounded p-1 ${i % 2 === 0 ? 'bg-gray-300' : ''}`}>
                        <h4 className={'font-semibold underline'}>Step #{i + 1}</h4>
                        <h5>Filters:</h5>
                        <Filters filters={step.filters}/>
                        <h5>Actions:</h5>
                        <Actions actions={step.actions}/>
                    </div>
                </article>
            ))}
        </section>
  )
}

export const WorkflowList = ({ workflows, handleEditWorkflow, product }: Props) => {
  const wfs = orderWorkflowsAsc(workflows)
  if (wfs.length <= 0) {
    return (
            <section className={'flex flex-col gap-2'}>
                <h3 className={'text-center font-semibold'}>
                    {`Create a new ${product ?? ''} workflow`}
                </h3>
            </section>
    )
  }
  return (
        <section className={'flex flex-col gap-2'}>
            {wfs.map((wf: Workflow, i: number) => {
              return (
                    <Accordion title={`${i + 1}. ${wf.name}`} key={wf.uuid}>
                        <div className={'flex flex-col'}>
                            <div className={'flex justify-end'}>
                                <Button
                                    outline={true}
                                    buttonColor='primary'
                                    classes='btn-outline-primary'
                                    onClick={() => handleEditWorkflow(wf)}
                                >
                                    Edit Workflow
                                </Button>
                                <br/>
                            </div>
                            <Scope scope={wf.scope}/>
                            <hr/>
                            <Steps steps={wf.steps}/>
                        </div>
                    </Accordion>
              )
            })}
        </section>
  )
}
