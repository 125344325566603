import { ComponentProps, FC } from 'react'

type Props = {
  isOpen: boolean
} & ComponentProps<'svg'>

export const ArrowIcon: FC<Props> = ({ isOpen, ...props }) => {
  return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${
                isOpen ? 'rotate-180' : ''
            } h-5 w-5 transition-transform transition-all duration-300`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            {...props}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
        </svg>
  )
}
