import React, { FC } from 'react'
import { Field } from 'formik'
import { Input } from '../ui/fields/Input'

type FieldProps = {
  label: string
  classes?: string
  type: string
  name: string
  error: Record<string, string>
} & Partial<HTMLInputElement>

export const InputField: FC<FieldProps> = (props) => {
  return (
      <Field {...props} as={Input}/>
  )
}
