import { useEffect, useState } from 'react'
import { customLocalStorage } from '../infrastructure/local.storage'

const persistentStorage = customLocalStorage

export const usePersistentStorage = <T>(key: string, initialValue: T) => {
  const [value, setValue] = useState<T>(() => {
    const valueFromStorage = persistentStorage.getItem<T>(key)

    return valueFromStorage ?? initialValue
  })

  useEffect(() => {
    persistentStorage.setItem(key, value)
  }, [key, value])

  return [value, setValue] as const
}
