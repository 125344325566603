import { FC, ReactNode } from 'react'
import { classNames } from '../../shared/utils/classnames'
interface Props {
  children: ReactNode
  className?: string
}
export const Card: FC<Props> = ({ children, className = '' }) => {
  return <div className={classNames('overflow-y-auto py-4 px-3 bg-gray-50 rounded shadow-lg', className)}>
        {children}
    </div>
}
