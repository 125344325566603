import { FC } from 'react'
import { ValidationErrors } from '../../../../shared/domain/error'
import { InputField } from '../../../formik/InputField'

interface PriceInputProps {
  name: string
  index: number
  errors: ValidationErrors
}

export const PriceInputs: FC<PriceInputProps> = ({ name, index, errors }) => {
  return <>
        <div className={'w-full md:w-1/4 px-3'}>
            <InputField classes='rounded' name={`${name}[${index}].price.label`} label={'Label'}
                   type={'text'}
                   error={errors}/>
        </div>
        <div className={'w-full md:w-1/4 px-3'}>
            <InputField classes='rounded' name={`${name}[${index}].price.type`} label={'type'}
                   type={'text'}
                   error={errors}/>
        </div>

        <div className={'w-full md:w-1/4 px-3'}>
            <InputField classes='rounded' name={`${name}[${index}].price.amount`} label={'Amount'}
                   type={'number'}
                   error={errors}/>
        </div>
        <div className={'w-full md:w-1/4 px-3'}>
            <InputField classes='rounded' name={`${name}[${index}].price.percentage`}
                   label={'Percentage'}
                   type={'number'}
                   error={errors}/>
        </div>
    </>
}
