import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Layout } from './sections/layout/Layout'
import { ProtectedRoute } from './components/ProtectedRoute'
import { Home } from './sections/home/view/Home'
import {
  WorkflowAddFactory,
  WorkflowEditFactory,
  WorkflowsFactory
} from './sections/workflows/view/WorkflowFactory'
import { CalculatorFactory } from './sections/calculator/views/CalculatorFactory'
import { UnAuthLayout } from './sections/layout/UnAuthLayout'
import { LoginFactory } from './sections/auth/views/LoginFactory'
import { AuthContextWrapper } from './sections/auth/views/AuthContextWrapper'

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthContextWrapper/>,
    children: [
      {
        path: '/',
        element: <ProtectedRoute><Layout/></ProtectedRoute>,
        children: [
          {
            index: true,
            path: '/',
            element: <Home/>
          },
          {
            path: '/workflows',
            element: <WorkflowsFactory/>
          },
          {
            path: '/workflow/add',
            element: <WorkflowAddFactory/>
          },
          {
            path: '/workflow/edit/:uuid',
            element: <WorkflowEditFactory/>
          },
          {
            path: '/calculator',
            element: <CalculatorFactory/>
          }
        ]
      },
      {
        path: '/login',
        element: <UnAuthLayout/>,
        children: [{
          path: '/login',
          element: <LoginFactory/>
        }]
      }]
  }
])

export const Router = () => {
  return <RouterProvider router={router}/>
}
