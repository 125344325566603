import { Http } from '../../../shared/domain/http'
import { CalculatorRepository } from '../domain/calculator.repository'
import { CalculatorRequest } from '../domain/calculator.request'
import { CalculatorResponse } from '../domain/calculator.response'
import { ListResponse } from '../domain/lists'

export const calculatorApiRepository = (http: Http) => (token: string): CalculatorRepository => {
  const headers = {
    authorization: `JWT ${token}`
  }
  return {
    submit: async (data: CalculatorRequest) => await http.post<CalculatorRequest, CalculatorResponse>('/calculator/', data, { headers }),
    getList: async () => await http.get<null, ListResponse>('/calculator/get-lists', null, { headers })
  }
}
