import {
  CalculatorRequest,
  getDefaultCalculatorValues
} from '../../sections/calculator/domain/calculator.request'
import Button from '../ui/Button'
import { Spinner } from '../icons/Spinner'
import { Formik } from 'formik'
import { ListResponse } from '../../sections/calculator/domain/lists'
import { FC } from 'react'
import { ValidationErrors } from '../../shared/domain/error'
import { DatePicker } from '../formik/DatePicker'
import { Grid, Col } from '../ui/Grid'
import { useNavigate } from 'react-router-dom'
import { CalculatorIcon } from '../icons/CalculatorIcon'
import { InputField } from '../formik/InputField'
import { DependantField } from '../formik/DependantField'
import { RadioField } from '../formik/RadioField'
import { Select } from '../formik/Select'

interface Props {
  listData: ListResponse
  companyName: string
  handleSubmit: (data: CalculatorRequest) => Promise<void>
  errors: ValidationErrors
  isSubmitting: boolean
}

export const CalculatorForm: FC<Props> = ({
  listData,
  companyName,
  handleSubmit,
  errors,
  isSubmitting
}) => {
  const navigate = useNavigate()
  const refreshPage = () => {
    navigate(0)
  }

  const { lists } = listData
  const insurerList = lists?.insurers ?? []
  return (
        <Formik
            initialValues={getDefaultCalculatorValues(companyName)}
            onSubmit={async (values) =>
              await handleSubmit(values as unknown as CalculatorRequest)
            }
        >
            {({ handleSubmit, values }) => {
              const vehicleYear = values.quoteDetails.risk.vehicle[0].year
              const vehicleValue = values.quoteDetails.risk.vehicle[0].value
              const displayVehicleYear = ((Boolean(errors) && Boolean(errors['quoteDetails.risk.vehicle[0].year'])) || vehicleYear.toString() !== '')
              const displayVehicleValue = ((Boolean(errors) && Boolean(errors['quoteDetails.risk.vehicle[0].value'])) || vehicleValue.toString() !== '')

              return (
                    <form
                        onSubmit={handleSubmit}
                        className='grid-flow-row'
                    >
                        <Grid cols={2} sm={2} classes='grid-cols-auto gap-4'>
                            <Col cols={1} classes='justify-self-start'>
                                <CalculatorIcon className='text-error' width={40}/>
                            </Col>
                            <Col cols={2} classes='col-span-2 justify-self-end'>
                                <Button outline={true} buttonColor='error' onClick={refreshPage}>
                                    Reset
                                </Button>
                            </Col>
                            <Col cols={1} classes='col-start-1'>
                                <DatePicker
                                    name={'quoteDetails.risk.vehicle[0].cover.date'}
                                    label={'Cover Date'}
                                    error={errors}
                                />
                            </Col>
                            <Col cols={1} classes='col-start-1'>
                                {insurerList.length <= 0
                                  ? <InputField
                                    name={'quoteDetails.quote.insurer'}
                                    label={'Insurer'}
                                    type={'text'}
                                    classes='input-ghost-primary rounded max-w-full bg-white'
                                    error={errors}
                                />
                                  : <Select
                                    name={'quoteDetails.quote.insurer'}
                                    label={'Insurer'}
                                    type={'select'}
                                    options={lists?.insurers ?? []}
                                    error={errors}
                                />}
                            </Col>
                            <Col cols={1} classes='hidden'>
                                <InputField
                                    name={'companyName'}
                                    label={'Company Name'}
                                    type={'text'}
                                    disabled={true}
                                    classes='input-ghost-primary max-w-full rounded bg-white'
                                    error={errors}
                                />
                            </Col>
                            <Col cols={1} classes='col-start-1'>
                                <Select
                                    name={'quoteDetails.product'}
                                    label={'Product'}
                                    type={'select'}
                                    options={lists?.products ?? []}
                                    error={errors}
                                />
                            </Col>
                            <Col cols={1} classes='col-span-1 col-start-1 sm:col-start-2'>
                                <Select
                                    name={'quoteDetails.quote.cover'}
                                    options={lists?.covers ?? []}
                                    label={'Cover'}
                                    type={'select'}
                                    error={errors}
                                    className='max-w-full'
                                />
                            </Col>
                            <Col cols={1} classes='col-start-1'>
                                <Select
                                    name={'quoteDetails.quote.payment.type'}
                                    options={lists?.payments ?? []}
                                    label={'Payment Type'}
                                    type={'select'}
                                    error={errors}
                                />
                            </Col>
                            <DependantField<CalculatorRequest>
                                field={values.quoteDetails.quote.payment.type}
                                value={'monthly'}
                            >
                                <Col cols={2} classes='col-span-3 sm:col-span-2'>
                                    <RadioField
                                        name={'quoteDetails.quote.payment.requestLowInitial'}
                                        label={'Low Initial Payment'}
                                        error={errors}
                                    />
                                </Col>
                                <Col cols={1} classes='col-start-1'>
                                    <InputField
                                        name={'quoteDetails.quote.payment.months'}
                                        label={'Number of months'}
                                        type={'number'}
                                        classes='input-ghost-primary rounded max-w-full bg-white'
                                        error={errors}
                                    />
                                </Col>

                            </DependantField>
                            <Col cols={1} classes='col-start-1'>
                                <InputField
                                    name={'quoteDetails.quote.premium'}
                                    label={'Premium'}
                                    type={'number'}
                                    classes='input-ghost-primary rounded max-w-full bg-white'
                                    error={errors}
                                />
                            </Col>
                            <Col cols={1} classes='col-start-1 sm:col-start-2'>
                                <InputField
                                    name={'quoteDetails.quote.voluntaryExcess'}
                                    label={'Voluntary Excess'}
                                    type={'number'}
                                    classes='input-ghost-primary rounded max-w-full bg-white'
                                    error={errors}
                                />
                            </Col>
                            <Col cols={1} classes='col-start-1'>
                                <InputField
                                    type='text'
                                    name={'quoteDetails.risk.vehicle[0].ncb.years'}
                                    label={'NCB Years'}
                                    classes='input-ghost-primary rounded max-w-full bg-white'
                                    error={errors}
                                />
                            </Col>
                            {displayVehicleValue && (
                                <Col cols={1} classes='col-start-1 col-span-1'>
                                    <InputField
                                        name={'quoteDetails.risk.vehicle[0].value'}
                                        label={'Value'}
                                        type={'number'}
                                        classes='input-ghost-primary rounded max-w-full bg-white'
                                        error={errors}
                                    />
                                </Col>
                            )}
                            {displayVehicleYear && (
                                <Col cols={2} classes='col-span-2 sm:col-span-1'>
                                    <InputField
                                        name={'quoteDetails.risk.vehicle[0].year'}
                                        label={'Year'}
                                        type={'number'}
                                        classes='input-ghost-primary rounded max-w-full bg-white'
                                        error={errors}
                                    />
                                </Col>
                            )}
                            <Col cols={3} classes='mt-4 col-span-3'>
                                <div className='flex items-center justify-center'>
                                    <Button
                                        buttonColor='primary'
                                        type={'submit'}
                                        disabled={isSubmitting}
                                        fullWidth={true}
                                    >
                                        Calculate {isSubmitting && <Spinner className={'h-4'}/>}
                                    </Button>
                                </div>
                            </Col>
                        </Grid>
                    </form>
              )
            }}
        </Formik>
  )
}
