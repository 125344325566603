import { FormikValues } from 'formik'
import { Loader } from '../../../components/Loader'
import { useList } from '../hooks/useList'
import { CalculatorRequest } from '../domain/calculator.request'
import { useCalculator } from '../hooks/useCalculator'
import { isValidationError } from '../../../shared/domain/error'
import { Quotes } from '../../../components/Quote'
import { useAuth } from '../../auth/hooks/useAuth'
import React, { FC } from 'react'
import { CalculatorRepository } from '../domain/calculator.repository'
import { CalculatorForm } from '../../../components/forms/CalculatorForm'
import { Header } from '../../../components/ui/Header'
import { Error } from '../../../components/Error'
import { toastError } from '../../../components/Toaster'
import { Container } from '../../../components/ui/Container'

interface Props {
  repository: CalculatorRepository
}

export const Calculator: FC<Props> = (props) => {
  const { user } = useAuth()
  const { data: listData, error: listError } = useList(props.repository)
  const {
    error,
    mutateAsync,
    isLoading: isSubmitting,
    data
  } = useCalculator(props.repository)

  if (listError !== null) {
    return (
            <Container className={'flex justify-center mt-20'}>
                <Error message={'Error retrieving data'}/>
            </Container>
    )
  }

  if (listData == null) {
    return <Loader message={'Loading data please wait'}/>
  }

  const handleSubmit = async (data: FormikValues) => await mutateAsync(data as CalculatorRequest).catch(e => {
    toastError(e)
  })
  const errors = error != null && isValidationError(error) ? error.errors : {}

  return (
        <Container>
            <section className={'flex flex-col gap-4 mb-2 items-center'}>
                <Header title={'Calculator'}/>
                <div className={'grid grid-cols-1 md:grid-cols-2 gap-2 w-full h-full'}>
                    <div className={'border-2 bg-gray-300 rounded shadow-md p-4'}>
                        <CalculatorForm
                            companyName={user?.companyName!}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            errors={errors}
                            listData={listData}
                        />
                    </div>
                    <div className={'border-2 bg-gray-300 rounded shadow-md p-4 overflow-y-auto'}>
                        <Quotes quotes={data?.quotes ?? []}/>
                    </div>
                </div>
            </section>
        </Container>
  )
}
