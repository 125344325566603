import toast, { Toaster as ReactHotToaster, ToastOptions } from 'react-hot-toast'
import React from 'react'
import { Error, isValidationError } from '../shared/domain/error'

export const Toaster = () => {
  return (
        <ReactHotToaster toastOptions={{
          position: 'bottom-right',
          success: {
            style: {
              color: '#4BB543',
              border: '1px solid #4BB543'
            }
          },
          error: {
            style: {
              color: '#f74065',
              border: '1px solid #f74065'
            }
          }

        }}/>
  )
}

export const toastError = (error: Error, options?: ToastOptions) => toast.error(`Something went wrong: ${isValidationError(error) ? error?._tag : error?.message}`, options)

export const toastSuccess = (message: string, options?: ToastOptions) => toast.success(message, options)
