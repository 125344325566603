import React, { FC } from 'react'
import { Field, useField, useFormikContext } from 'formik'
import { Errors } from '../ui/fields/Errors'

type FieldProps = {
  label: string
  classes?: string
  type: string
  name: string
  error: Record<string, string>
} & Partial<HTMLInputElement>

interface RadioOption {
  value: string
  label: string
}

const defaultRadioOptions: RadioOption[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' }
]

export const RadioField: FC<Omit<FieldProps, 'type'> & { options?: RadioOption[] }> = ({
  name,
  label,
  options = defaultRadioOptions,
  error,
  ...props
}) => {
  const [field] = useField(name)
  const { setFieldValue } = useFormikContext()

  const onRadioChange = (val: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, val.target.value === 'true')
  }

  const _error = error[name] != null ? error[name] : ''

  return (
        <div className={'flex flex-col'}>
            <label
                className={'block  text-sm font-semibold my-1'}
                htmlFor={name}
            >
                {label}
            </label>
            <div className='min-w-fit w-1/5 h-8 flex'>
                <div className={'flex'}>
                    {options.map((o, i) => (
                        <div className='w-full' key={o.label}>
                            <label
                                className={`bg-white focus-within:outline-none border ${_error !== '' ? 'border-red-900' : 'border-gray-900'} focus-within:ring focus-within:ring-blue-400 hover:bg-blue-100 text-sm font-medium text-center  mr-3 flex h-full w-full items-center ${i % 2 === 0 ? 'rounded-l' : 'border-l-0 rounded-r'}`}
                            >
                                <Field
                                    className='opacity-0 absolute peer '
                                    name={name}
                                    value={o.value}
                                    checked={o.value === field?.value?.toString()}
                                    onChange={onRadioChange}
                                    {...props}
                                    type={'radio'}
                                />
                                <span
                                    className={`flex h-full w-full border items-center pl-4 pr-2  peer-checked:bg-blue-800 peer-checked:text-white ${i % 2 === 0 ? 'rounded-l' : 'border-l-0 rounded-r'}`}
                                >
                                {o.label}
                            </span>
                            </label>
                        </div>
                    ))}
                </div>
                <Errors message={_error}/>
            </div>
        </div>
  )
}
