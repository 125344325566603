import { WorkflowRepository, Response, Request } from '../domain/workflow.repository'
import { Workflow } from '../domain/workflow'
import { Http } from '../../../shared/domain/http'

export const workflowsApiRepository = (http: Http) => (token: string): WorkflowRepository => {
  const headers = {
    authorization: `JWT ${token}`
  }

  return {
    getAll: async (company: string) => await http.get<{ companyName: string }, Response['getAll']>('/workflows/get-all', { companyName: company }, { headers }),
    getSettings: async () => await http.get<null, Response['getSettings']>('/settings/get-all', null, { headers }),
    addWorkflow: async (data: Workflow) => await http.post<Workflow, Response['addWorkflow']>('/workflow/add', data, { headers }),
    editWorkflow: async (uuid: string, data: Workflow) => await http.patch<Workflow, Response['editWorkflow']>(`/workflow/${uuid}`, data, { headers }),
    getByUUID: async (uuid: string) => await http.get<null, Response['getByUUId']>(`/workflow/${uuid}`, null, { headers }),
    saveWorkflowReorder: async (data: Request['saveReorder']): Promise<Response['saveReorder']> => await http.patch<Request['saveReorder'], Response['saveReorder']>('/workflows/order', data, { headers })
  }
}
