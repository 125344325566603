import { MakeAllString } from '../../../shared/utils/type.utilities'

export interface Vehicle {
  year: number
  cover: {
    date: Date
  }
  ncb: {
    years: number
  }
  value: number
}
interface QuotePayment {
  type: 'annually' | 'monthly'
  months: number
  requestLowInitial: boolean
}
export interface Quote {
  insurer: string
  premium: number
  cover: string
  voluntaryExcess: number
  payment: QuotePayment
}
export interface Risk {
  vehicle: Vehicle[]
}
export type Product = string

export interface QuoteDetails {
  risk: Risk
  quote: Quote
  product: Product
}

export interface CalculatorRequest {
  companyName: string
  quoteDetails: QuoteDetails
}
type StringQuoteDetails = MakeAllString<QuoteDetails>
type StringCalculatorRequest = MakeAllString<CalculatorRequest>
type StringQuote = MakeAllString<Quote>

type DefaultCalculatorValues = Omit<StringCalculatorRequest, 'quoteDetails'> & {
  quoteDetails: Omit<StringQuoteDetails, 'quote'> & {
    quote: Omit<StringQuote, 'cover' | 'payment'> & {
      cover: 'comprehensive'
      payment: QuotePayment
    }
  }
}

export const getDefaultCalculatorValues = (companyName: string): DefaultCalculatorValues => ({
  companyName,
  quoteDetails: {
    risk: {
      vehicle: [{
        value: '',
        cover: {
          date: ''
        },
        year: '',
        ncb: {
          years: ''
        }
      }]
    },
    quote: {
      //      uuid: '723294b1-6d67-47be-8a13-dc34b8d2f123',
      insurer: '',
      cover: 'comprehensive',
      voluntaryExcess: '',
      payment: {
        type: 'monthly',
        requestLowInitial: false,
        months: 11
      },
      premium: ''
    },
    product: ''
  }
})
