import Button from '../../ui/Button'

import {
  Filter, FilterApi, FilterApply,
  SettingsApi
} from '../../../sections/workflows/domain/workflow'
import { FC } from 'react'
import { ValidationErrors } from '../../../shared/domain/error'
import { FieldArrayRenderProps } from 'formik'
import { camelCaseSeparator } from '../../../shared/utils/string'
import { Select } from '../../formik/Select'
import { InputField } from '../../formik/InputField'

type FilterRecord = Record<string, FilterApi>

interface FilterInputProps {
  name: string
  title: string
  filter: Filter
  index: number
  errors: ValidationErrors
  helpers: FieldArrayRenderProps
  settingsData: SettingsApi
}

const getFilterTypes = (settings: any) => {
  const filters: FilterRecord = {}
  settings.filters.forEach((filter: FilterApi) => {
    filters[filter?.type] = filter
  })
  return filters
}

const filterTypesOptions = (settings: any) => {
  const options: Array<{ label: string, value: string }> = []
  Object.keys(getFilterTypes(settings)).forEach((key: string) => options.push({ label: camelCaseSeparator(key), value: key }))
  return options
}

const filterApplyOptions = (filter: Filter, filterTypes: FilterRecord) => {
  const options: Array<{ label: string, value: string }> = []
  if (filter?.type === 'Choose a value' || filter?.type === '') { return options }
  const availableApply: string[] = filterTypes[filter.type].apply.split('|')
  if (availableApply.includes(FilterApply.equal)) {
    options.push({ label: 'Equal', value: FilterApply.equal })
  }
  if (availableApply.includes(FilterApply.range)) {
    options.push({ label: 'Range', value: FilterApply.range })
  }
  return options
}

const getFilterInputs = (filter: Filter, name: string, index: number, errors: ValidationErrors) => {
  switch (filter.apply) {
    case FilterApply.equal:
      return <div className={'w-full md:w-1/4 px-3'}>
            <InputField classes='rounded' name={`${name}[${index}].equal`} label={'Equal'} type={'text'}
                   error={errors}/>
        </div>
    case FilterApply.range:
      return <> <div className={'w-full md:w-1/4 px-3'}>
              <InputField classes='rounded' name={`${name}[${index}].min`} label={'Min'} type={'number'}
                     error={errors}/>
          </div>
          <div className={'w-full md:w-1/4 px-3'}>
              <InputField classes='rounded' name={`${name}[${index}].max`} label={'Max'} type={'number'}
                     error={errors}/>
          </div>
      </>
    default:
      return <></>
  }
}

export const FilterInputs: FC<FilterInputProps> = ({ name, title, filter, index, errors, helpers, settingsData }) => {
  const filterTypes = getFilterTypes(settingsData)
  return <article key={index}
                    className={'p-2 bg-gray-50 rounded border border-gray-200 shadow-md'}>
        <div className={'flex justify-between items-center'}>
            <h2 className='font-semibold'>{title} #{index + 1}</h2>
            <Button classes='' buttonColor='error' outline={true} onClick={() => helpers.remove(index)}>x</Button>
        </div>
        <div className="flex flex-wrap -mx-3">
            <div className={'w-full md:w-1/4 px-3'}>
                <Select options={filterTypesOptions(settingsData)} name={`${name}[${index}].type`}
                        label={'Type'} type={'select'} error={errors}/>
            </div>
            <div className={'w-full md:w-1/4 px-3'}>
                <Select options={filterApplyOptions(filter, filterTypes)}
                        name={`${name}[${index}].apply`}
                        label={'Apply'} type={'select'} error={errors}/>
            </div>
            {getFilterInputs(filter, name, index, errors)}
        </div>
    </article>
}
