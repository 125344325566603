import React, { FC, InputHTMLAttributes } from 'react'
import { classNames } from '../../../shared/utils/classnames'
import { Errors } from './Errors'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  error: Record<string, string>
  name: string
  label: string
  classes?: string
}
export const Input: FC<Props> = ({ name, error, label, classes, ...rest }) => {
  const _error = error[name] != null ? error[name] : ''
  return <div hidden={rest.hidden}>
        <label
            htmlFor={name}
            hidden={rest.hidden}
            className='text-sm font-medium m-1'
        >
            {label}
        </label>
        <input
            {...{ ...rest, name }}
            className={classNames(classes, `input-xs input input-ghost-primary border focus:outline-none focus:shadow-outline ${(_error !== '') ? 'border-red-900' : 'border-gray-900'} bg-white`)}
        />
        <Errors message={_error}/>
    </div>
}
