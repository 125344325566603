import { Quote as QuoteD, Addon, Fee, filterAppliedAddons } from '../sections/calculator/domain/calculator.response'
import React, { FC, Fragment, useState } from 'react'
import { formatPrice } from '../shared/utils/number'
import { Table } from './ui/Table'
import { DocumentTextIcon } from './icons/DocumentTextIcon'

const FeesTable: FC<{ fees: Fee[] }> = ({ fees }) => {
  return <Table
      items={fees}
      headers={['Amount']}
      renderHeader={headers => (<tr className={'bg-primary text-white border-b-2 border-gray-200 w-full'}>
        <th className="w-1/4 border-2 p-1 text-sm font-semibold tracking-wide text-left"><DocumentTextIcon
            width={20}/></th>
        {headers.map((header, index) => (<th key={`fee-header-${index}`}
                                             className="w-1/4 border-2 p-1 text-sm font-semibold tracking-wide text-left">{header}</th>))}
      </tr>)
      }
      renderCol={(item, i) => (<tr key={i} className={`${i % 2 === 0 ? 'bg-gray-500' : ''}`}>
        <td className="whitespace-nowrap p-1 text-sm font-semibold capitalize">
          {item.label}
        </td>
        <td className="whitespace-nowrap p-1 text-sm font-semibold">
          {formatPrice(item.amount)}
        </td>
      </tr>)}
  />
}
const AddonsTable: FC<{ addons: Addon[] }> = ({ addons }) => {
  return <Table
      items={addons}
      headers={['price', 'discount', 'total']}
      renderHeader={headers => (<tr className={'bg-primary text-white border-b-2 border-gray-200 w-full'}>
        <th className="w-1/4 border-2 p-1 text-sm font-semibold tracking-wide text-left"><DocumentTextIcon
            width={20}/></th>
        {headers.map((header, index) => (<th key={`fee-header-${index}`}
                                             className="w-1/4 border-2 p-1 text-sm font-semibold tracking-wide text-left">{header}</th>))}
      </tr>)
      }
      renderCol={(item, i) => (<tr key={i} className={`${i % 2 === 0 ? 'bg-gray-500' : ''}`}>
        <td className="whitespace-nowrap p-1 text-sm font-semibold capitalize">
          {item.label}
        </td>
        <td className="whitespace-nowrap p-1 text-sm font-semibold capitalize">
          {formatPrice(item.price)}
        </td>
        <td className="whitespace-nowrap p-1 text-sm font-semibold capitalize">
          {formatPrice(item.discount)}
        </td>
        <td className="whitespace-nowrap p-1 text-sm font-semibold capitalize">
          {formatPrice(item.price - item.discount)}
        </td>
      </tr>)}
  />
}

export const Quotes: FC <{ quotes: QuoteD[] }> = ({ quotes }) => {
  const [expandRow, setExpandRow] = useState<string>('')
  const handleExpand = (rowId: string) => setExpandRow(rowId === expandRow ? '' : rowId)
  return quotes.length > 0
    ? (
          <Table headers={['Total', 'Discount', 'Addons', 'Fees']} items={quotes}
                 renderHeader={(headers) => {
                   return (<tr>{headers.map((header, index) => <th
                       key={`headCell-${index}`}
                       className="border-x-2 bg-error text-white sticky top-0 z-10 text-md px-2 py-3.5 text-left font-semibold backdrop-blur backdrop-filter sm:table-cell"
                   >
                     {header}
                   </th>)}</tr>)
                 }}
                 renderCol={(item, index) => {
                   const isRowExpanded = expandRow === item.uuid
                   return (
                       <Fragment key={item.uuid}>
                         <tr className={`${index % 2 === 0 ? 'bg-gray-500' : 'bg-gray-200'} hover:bg-green-400 cursor-pointer ${isRowExpanded ? 'bg-green-400' : ''}`}
                             onClick={() => handleExpand(item.uuid)}>
                           <td className={`${index % 2 === 0 ? 'bg-yellow-500' : 'bg-yellow-200'} whitespace-nowrap p-2 font-semibold sm:table-cell`}>{formatPrice(item.total)}</td>
                           <td className={'whitespace-nowrap p-2 font-semibold sm:table-cellfont-semibold'}>{formatPrice(item.discount)}</td>
                           <td>
                             <div
                                 className={'whitespace-nowrap p-2 font-semibold sm:table-cell flex flex-col capitalize font-semibold'}>{filterAppliedAddons(item.addons).map((addon) =>
                                 <p key={addon.type} className={'m-0'}>{addon.label}</p>)}</div>
                           </td>
                           <td>
                             <div
                                 className={'whitespace-nowrap p-2 font-semibold sm:table-cell flex flex-col capitalize font-semibold'}>{item.fees.map(fee =>
                                 <p key={fee.type} className={'m-0'}>{fee.label}</p>)}</div>
                           </td>
                         </tr>
                         {isRowExpanded && <tr className="bg-gray-200">
                           <td className="p-2" colSpan={4}>
                             <div className={'flex gap-4 p-1 text-md font-semibold my-2'}>
                               <div>
                                 <p>Premium Discount</p>
                                 <p>{formatPrice(item.premiumDiscount)}</p>
                               </div>
                               <div>
                                 <p>Expiration Date</p>
                                 <p>{new Date(item.expirationDate).toLocaleDateString()}</p>
                               </div>
                             </div>
                             <div className={'mt-2'}/>
                             <AddonsTable addons={filterAppliedAddons(item.addons)}/>
                             <div className={'mt-2'}/>
                             <FeesTable fees={item.fees}/>
                             <div className={'mt-2'}/>
                           </td>
                         </tr>}
                       </Fragment>
                   )
                 }}/>
      )
    : <div><h2 className='p-2 text-xl font-semibold text-center'>Quotes</h2></div>
}
