import React, { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { Card } from './ui/Card'

interface Props {
  title: string
  icon: ReactNode
  route: string
}

const HomeCard: React.FunctionComponent<Props> = ({ title, icon, route }) => {
  return (
        <Card
            className={'bg-white border-2 rounded-lg shadow-lg overflow-hidden flex items-center hover:border-indigo-500 transition-all'}>
            <NavLink to={route}>
                <div className="px-6 py-4 text-center">
                    <h2 className="text-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset font-semibold">{title}</h2>
                    {icon}
                </div>
            </NavLink>
        </Card>

  )
}

export default HomeCard
