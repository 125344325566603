import { createContext, ReactNode, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePersistentStorage } from '../../../shared/hooks/useLocalStorage'
import { User } from '../domain/user'

interface Context {
  user: User | null
  login: (user: User) => void
  logout: () => void
}
interface State {
  prevLocation: Location
}
export const AuthContext = createContext<Context | null>(null)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = usePersistentStorage<User | null>('user', null)
  const location = useLocation()
  const navigate = useNavigate()

  const login = (user: User) => {
    setUser(user)
    if (location.state != null && location?.state?.prevLocation != null) {
      const { state } = location as { state: State }
      navigate(`${state.prevLocation.pathname}${state.prevLocation.search}`)
    } else {
      navigate('/calculator')
    }
  }

  const logout = () => {
    setUser(null)
    navigate('/login', { state: { prevLocation: location } })
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user, location]
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
