import { useAuth } from '../sections/auth/hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth()
  const location = useLocation()
  if (user == null) {
    return <Navigate to={'/login'} replace={true} state={{ prevLocation: location }}/>
  }

  return children
}
