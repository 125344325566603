import { useQuery } from 'react-query'
import { getProductsAndWorkflows, Workflow } from '../domain/workflow'
import { Error } from '../../../shared/domain/error'
import { WorkflowRepository } from '../domain/workflow.repository'

export const useGetAllWorkflowsByCompanyName = (repository: WorkflowRepository, company: string) => useQuery<{ workflows: Workflow[], products: string[], workflowsOrderedByProduct: Record<string, Workflow[]> }, Error>(
  ['workflows-get-all'],
  async () => {
    const response = await repository.getAll(company)
    const { products, workflows: workflowsOrderedByProduct } = getProductsAndWorkflows(response.workflows)

    return { workflows: response.workflows, workflowsOrderedByProduct, products }
  },
  {
    refetchOnWindowFocus: 'always'
  }
)
