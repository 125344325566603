import { Outlet } from 'react-router-dom'
import { AuthProvider } from '../context/auth'
import React from 'react'

import { useInterceptors } from '../../../shared/hooks/useInterceptors'

const SetupInterceptors = () => {
  useInterceptors()
  return <Outlet/>
}
export const AuthContextWrapper = () => {
  return (
        <AuthProvider><SetupInterceptors/></AuthProvider>
  )
}
