import { FC } from 'react'
import { FieldArray, getIn, useFormikContext } from 'formik'
import {
  Action, SettingsApi,
  Workflow
} from '../../../sections/workflows/domain/workflow'
import Button from '../../ui/Button'
import { ValidationErrors } from '../../../shared/domain/error'
import { ActionInputs } from './ActionInputs'

interface ActionProps {
  name: string
  errors: ValidationErrors
  settingsData: SettingsApi
}

export const Actions: FC<ActionProps> = ({ name, errors, settingsData }) => {
  const { values } = useFormikContext<Workflow>()
  const actions = getIn(values, name) as Action[]

  return (
        <FieldArray name={`${name}`} render={helpers => {
          return <section className={'flex flex-col gap-2'}>
                {
                    actions.map((action, index) => {
                      return <ActionInputs key={index} name={name} type={action?.type} index={index} errors={errors}
                                           helpers={helpers} settingsData={settingsData}/>
                    })
                }
                <div>
                  <Button
                      outline={true}
                      classes='my-1'
                      type={'button'}
                      buttonColor={'success'}
                      onClick={() => helpers.push(settingsData.defaultFormValues.action)}
                  >
                    Add Action
                  </Button>
                </div>

            </section>
        }}/>
  )
}
