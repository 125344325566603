import HomeCard from '../../../components/HomeCard'
import { Grid, Col } from '../../../components/ui/Grid'
import { useAuth } from '../../auth/hooks/useAuth'
import { getCanAccessWorkflows } from '../../auth/domain/user'
import { Container } from '../../../components/ui/Container'
import { CalculatorIcon } from '../../../components/icons/CalculatorIcon'
import { ClipboardDocumentListIcon } from '../../../components/icons/ClipboardDocumentListIcon'
import React from 'react'

export const Home = () => {
  const { user } = useAuth()
  const canAccessWorkflows = getCanAccessWorkflows(user!)

  return (
        <Container className={'h-full w-full'}>
            <section className='h-full w-full flex flex-col justify-center items-center'>
                <div className='flex flex-1 items-center justify-center w-full'>
                    <Grid cols={1} sm={canAccessWorkflows ? 2 : 1} classes='gap-2'>
                        {canAccessWorkflows
                          ? <Col cols={1} classes='h-full w-full flex items-center justify-center'>
                                <HomeCard route='/workflows' title='Go To Workflows'
                                          icon={
                                              <div
                                                  className={'bg-yellow-100 text-yellow-700 rounded-lg inline-flex p-3 ring-4 ring-white'}>
                                                  <ClipboardDocumentListIcon
                                                      className={'h-12 w-12 bg-yellow-100 text-yellow-700'}/>
                                              </div>
                                          }
                                />
                            </Col>
                          : <></>}
                        <Col cols={1} classes='h-full w-full flex items-center justify-center'>
                            <HomeCard route='/calculator' title='Go To Calculator'
                                      icon={
                                          <div
                                              className={'bg-rose-50 text-rose-700 rounded-lg inline-flex p-3 ring-4 ring-white'}>
                                              <CalculatorIcon className={'h-12 w-12 bg-rose-50 text-rose-700'}/>
                                          </div>
                                      }

                            />
                        </Col>
                    </Grid>
                </div>
            </section>
        </Container>
  )
}
