import { http } from '../../../shared/infrastructure/http'
import React from 'react'
import { WorkflowAdd } from './WorkflowAdd'
import { WorkflowEdit } from './WorkflowEdit'
import { Workflows } from './Workflows'
import { workflowsApiRepository } from '../infrastructure/workflows.api.repository'
import { useAuth } from '../../auth/hooks/useAuth'

const repository = workflowsApiRepository(http)

export const WorkflowAddFactory = () => {
  const { user } = useAuth()
  return <WorkflowAdd repository={repository(user?.auth.token!)}/>
}
export const WorkflowEditFactory = () => {
  const { user } = useAuth()
  return <WorkflowEdit repository={repository(user?.auth.token!)}/>
}
export const WorkflowsFactory = () => {
  const { user } = useAuth()
  return <Workflows repository={repository(user?.auth.token!)}/>
}
