import { Modify } from '../../../shared/utils/type.utilities'
import { createHashTable } from '../../../shared/utils/hash'

export enum FilterType {
  product = 'product',
  insurer = 'insurer',
  premium = 'premium',
  voluntaryExcess = 'voluntaryExcess',
  payment = 'payment',
  months = 'months',
  requestLowInitial = 'requestLowInitial',
  vehicleAge = 'vehicleAge',
  vehicleValue = 'vehicleValue'
}

export enum ActionType {
  setFee = 'setFee',
  setDiscount = 'setDiscount',
  setInstalmentFee = 'setInstalmentFee',
  setDiscountPremium = 'setDiscountPremium',
  addAddon = 'addAddon',
  setExpiration = 'setExpiration',
  setInterestRate = 'setInterestRate',
  setInitialPayment = 'setInitialPayment'
}

export interface ActionPrice {
  amount: number
  percentage: number
  type: string
  label: string
}

export interface ActionAddon {
  required: boolean
  label: string
  type: string
  price: number
  discount: number
}

export interface Action {
  type: string
  price?: ActionPrice
  date: number | null
  addon?: ActionAddon
}

export interface InputDetails {
  label: string
  type: string
}

export type InputList = Record<string, InputDetails>

export interface ActionApi {
  type: string
  inputs?: InputList
  input_type?: string
}

export enum FilterApply {
  equal = 'equal',
  range = 'range'
}

export interface FilterApi {
  type: string
  apply: string
}

export interface SettingsApi {
  filters: FilterApi[]
  actions: ActionApi[]
  defaultFormValues: {
    action: Record<string, any>
    filter: Record<string, any>
  }
}

export interface Step {
  uuid: string
  companyName: string
  active: boolean
  filters: Filter[]
  actions: Action[]
  continue: boolean
}

export interface Filter {
  type: string
  apply: string
  equal: string | null
  min: number | null
  max: number | null
}

export type EmptyFilter = Omit<Filter, 'type' | 'apply'> & {
  type: string
  apply: string
  equal: null
  min: null
  max: null
}

export type EqualityFilter = Filter & {
  apply: FilterApply.equal
}
export type RangeFilter = Filter & {
  apply: FilterApply.range
}
export interface Workflow {
  id: string
  uuid: string
  companyName: string
  name: string
  order: number
  scope: Filter[]
  steps: Step[]
}
export type DraggableWorkflow = Workflow & { dragId: string }

const findProductFilter = (filters: Filter[]) => filters.find(f => f.type === FilterType.product)

export const getProductFromWorkflow = (workflow: Workflow) => {
  const filter = findProductFilter(workflow.scope)
  return filter?.equal ?? 'global'
}

export const getProductsAndWorkflows = (workflows: Workflow[]) => workflows
  .reduce<{ products: string[], workflows: Record<string, Workflow[]> }>((acc, curr) => {
  const scopeWithProduct = findProductFilter(curr.scope)
  const product = scopeWithProduct?.equal ?? 'global'
  const aux = acc.workflows[product] ?? []
  return {
    ...acc,
    products: acc.products.includes(product) ? acc.products : [...acc.products, product],
    workflows: { ...acc.workflows, [product]: [...aux, curr] }
  }
}, { products: ['global'], workflows: { global: [] } })

export const getIsEqualityFilter = (filter: Filter): filter is EqualityFilter => {
  return filter.apply === 'equal'
}
export const getIsRangeFilter = (filter: Filter): filter is RangeFilter => {
  return filter.apply === 'range'
}
export const getIsAnActionPrice = (type: ActionType): type is ActionType => {
  switch (type) {
    case 'setFee':
    case 'setDiscount':
    case 'setInstalmentFee':
    case 'setDiscountPremium':
    case 'setInterestRate':
    case 'setInitialPayment':
      return true
    default:
      return false
  }
}

export type EmptyWorkflow = Modify<Omit<Workflow, 'uuid' | 'id'>, {
  companyName: string
  name: string
  order: string
  scope: []
  steps: []
}>

export const orderWorkflowsAsc = (workflows: Workflow[]) => workflows.sort((a, b) => a.order - b.order)

export const getIsSameWorkflowsOrder = (previousWorkflows: Workflow[], newWorkflows: Workflow[]) => previousWorkflows.every((wf, index) => wf.order === newWorkflows[index].order)
export const reorderWorkflows = (previousWorkflows: Workflow[], draggableWorkflows: DraggableWorkflow[]): Workflow[] => {
  const newWorkflows = draggableWorkflows.map(({ dragId, ...rest }) => ({ ...rest }))
  const hashList = createHashTable(previousWorkflows, 'order')
  return newWorkflows.map((workflow, index) => ({ ...workflow, order: hashList[index] })) as Workflow[]
}
